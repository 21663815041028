<template>
  <ResponsivePage>
    <div class="t-project">
      <HeroImage :alt="title"
        :imgref="pageHeroImage.desktop"
        :imgSet="pageHeroImage.mobile + ' 548w, ' + pageHeroImage.tablet + ' 734w, ' + pageHeroImage.desktop + ' 2876w'"
        :imgSizes="'(max-width: 414px) 548px, (max-width: 768px) 734px, 2876px'"
      />
      <div class="three-button-tab">
        <ThreeButtonTab
        :button1Link="'/buddhism'"
        :button2Link="'/meditation'"
        :button3Link="'/triratna'"
        />
      </div>
    </div>
    <div :class="{ 'keystone-header' : true, 'keystone-header__open' : sidebarOpen }">
      <div class="keystone-header__navigation">
        <div class="caret-right-icon__wrapper" @click.prevent="() => sidebarOpen = !sidebarOpen"><font-awesome-icon class="caret-right-icon" :icon="['fa', 'caret-right']" /></div>
        <button type="button" @click.prevent="() => sidebarOpen = !sidebarOpen" class="keystone-sidebar-toggle"><span class="keystone-sidebar-toggle__open">table of contents</span><span class="keystone-sidebar-toggle__close">close</span></button>
      </div>
    </div>

    <div v-if="!published" class="alert alert-info">This is a draft/work in progress</div>

    <div :class="{ 'keystone' : true, 'keystone__open' : sidebarOpen }">
      <div class="keystone-main" @click="() => sidebarOpen = false">
        <h1>{{ pageTitle }}</h1>
        <EditorBlocks :blocks="pageBlocks" />
        <div class="separator-container">
          <div class="body-separator"/>
        </div>
        <div v-if="nextLink">
          <font-awesome-icon class="caret-right-icon_nextLink" :icon="['fa', 'caret-right']" /> <a class="next-link" :href="nextLink.href" @click.prevent="loadPage(nextLink.href)"><b>next page: </b>{{ nextLink.label }}</a>
        </div>
      </div>
      <div :class="{ 'keystone-sidebar' : true, 'keystone-sidebar__open' : sidebarOpen }">
        <h2><a :href="'/' + urlSlug" title="Keystone main page">{{ name }}</a></h2>
        <div class="keystone-index">
          <div v-for="(links, section) in index" :key="section" class="keystone-index-section">
            <h3>{{ section }}</h3>
            <ul class="keystone-index-links">
              <li v-for="link in links" :key="link.label" class="keystone-index-link">
                <a :class="{ active: isActive(link.href) }" :href="'/' + link.href" @click.prevent="loadPage(link.href)">{{ link.label }}</a>
                <ul v-if="link.links && link.links.length" class="keystone-index-sublinks">
                  <li v-for="link in link.links" :key="link.label" class="keystone-index-sublink">
                    <a :class="{ active: isActive(link.href) }" :href="'/' + link.href" @click.prevent="loadPage(link.href)">{{ link.label }}</a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div class="newsletter-section">
            <div class="t-tbco">
              <NewsletterSignup
                :hidden="false"
                title="Get the Triratna Highlights Newsletter"
                buttonColor="green"
                theme="t-sidebar"
              />
            </div>
          </div>
          <div class="go-deeper-section">
            <div class="go-deeper-text">go deeper</div>
            <a class="go-deeper-link" :href="'/text/triratna-around-world'">
              <img src="./images/Triratna-white-background_1 (1).jpg">
              <div class="img-box blue">
                find a triratna buddhist centre near you
              </div>
            </a>
            <a class="go-deeper-link" :href="'/stories/toolkit/'">
              <img src="./images/Logo-1024-x-1024 (1).jpg">
              <div class="img-box purple">
                live buddhist classes and events: take part online
              </div>
            </a>
            <a class="go-deeper-link" :href="'/stories/toolkit/home-retreats/'">
              <img src="./images/Meditate Online Square (1).jpg">
              <div class="img-box green">
                home retreats: resources for practice wherever you live
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </ResponsivePage>
</template>

<script>
import { NewsletterSignup } from '@dharmachakra/newsletter-signup-component'
import { getNextLink, getFirstLink } from '../utils/pages.js'
import ResponsivePage from './ResponsivePage'
import EditorBlocks from '../components/EditorBlocks'
import HeroImage from '@dharmachakra/tbco-shared-styles/src/components/HeroImage'
import ThreeButtonTab from '../components/ThreeButtonTab.vue'
import api from '../api/keystones-api.js'
import { fetchPageData } from '../api/browser-api.js'
import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCaretRight } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faCaretRight)
Vue.component('font-awesome-icon', FontAwesomeIcon)

export default {
  components: {
    ResponsivePage,
    EditorBlocks,
    HeroImage,
    ThreeButtonTab,
    NewsletterSignup
  },
  props: {
    id: [String, Number],
    name: String,
    urlSlug: String,
    title: String,
    published: Boolean,
    index: Object,
    content: String,
    heroImage: String,
    marqueeImage: Object,
    blocks: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      sidebarOpen: false,
      pageContent: this.content,
      pageTitle: this.title,
      pageBlocks: this.blocks,
      pageHeroImage: this.heroImage,
      activeLink: null
    }
  },
  created() {
    this.setPageHeroImage(this.marqueeImage)
    this.activeLink = window.location.pathname.substr(1)
    if (this.activeLink.indexOf('/') === -1) {
      this.activeLink = getFirstLink(this.index).href
    }
  },
  methods: {
    setPageHeroImage(marqueeImage) {
      if (marqueeImage) {
        this.pageHeroImage = {
          desktop: marqueeImage.path,
          tablet: marqueeImage.path,
          mobile: marqueeImage.path
        }
      } else {
        const pageData = fetchPageData()
        this.pageHeroImage = pageData.heroImage
      }
    },
    loadPage(link) {
      api.fetchPageByUrlSlug(this.id, link)
        .then(data => {
          this.pageTitle = data.page.title
          this.pageContent = data.page.text
          this.pageBlocks = data.page.json.blocks
          this.sidebarOpen = false
          this.activeLink = link
          this.setPageHeroImage(data.page.marquee_media)

          const canonicalLink = document.querySelector("link[rel='canonical']")
          const isCanonicalPage = this.urlSlug + '/' + data.page.urlSlug === getFirstLink(this.index).href

          if (!isCanonicalPage && canonicalLink) {
            canonicalLink.remove()
          }

          if (isCanonicalPage && !canonicalLink) {
            const linkTag = document.createElement('link')
            linkTag.setAttribute('rel', 'canonical')
            linkTag.href = '/' + this.urlSlug
            document.head.appendChild(linkTag)
          }

          history.pushState({}, '', '/' + link)
          document.title = data.page.title + ' | ' + this.name + ' | The Buddhist Centre'
          window.scrollTo(0, 0)
        })
        .catch((err) => {
          console.error(err)
        })
    },
    isActive(link) {
      return this.activeLink === link
    }
  },
  computed: {
    currentBaseUrl() {
      return window.location.origin + '/'
    },
    nextLink() {
      return getNextLink(this.index, this.isActive)
    }
  }
}
</script>

<style scoped lang="scss">
@import '@dharmachakra/tbco-shared-styles/src/assets/scss/partials/_variables.scss';

::v-deep .keystone {
  font-family: 'Source Sans Pro', sans-serif;
  margin-bottom: 3rem;

  p, ul, ol {
    font-size: 20px;
    letter-spaceing: 0.59px;
  }

  a {
    color: #000;
  }
}

.keystone-header {
  padding: 1rem 0 0 0;
  font-family: 'Source Sans Pro', sans-serif;
  .keystone-header__navigation {
    display: flex;
    @media all and (min-width: $desktop-size) {
      display: none;
    }
    .caret-right-icon__wrapper {
      background: #f2f2f2;
      padding: 12px 5px;
      font-size: 1.75rem;
      top: 3px;
      position: relative;
      color: #E4611D;
      margin-right: 10px;
    }
    .keystone-sidebar-toggle {
      z-index: 100;
      color: RGB(228, 97, 29);
      border: 0;
      background: none;
      text-decoration: underline;
      &:hover {
        cursor: pointer;
      }
      .caret-right-icon {
        margin-right: 1rem;
        font-size: 1.75rem;
      }
    }
  }
}
.keystone-sidebar-toggle__close {
  display: none;
}
.keystone-sidebar-toggle__open {
  display: inline;
}
.keystone-header__open {
  .keystone-sidebar-toggle__close {
    display: inline;
  }
  .keystone-sidebar-toggle__open {
    display: none;
  }
}

.keystone {
  display: flex;
  overflow: hidden;

  transition-property: all;
  transition-timing-function: ease-out;
  transition-duration: 500ms;

  @media all and (min-width: $desktop-size) {
    gap: 3em;
  }
}

.keystone-sidebar {
  position: relative;
  z-index: 100;
  order: -1;
  width: 100%;
  margin-left: -100%;
  @media screen and (min-width: 769px) {
    width: 80%;
    margin-left: -80%;
  }
  flex-shrink: 0;
  background-color: #fff;

  transition-property: all;
  transition-timing-function: ease-out;
  transition-duration: 500ms;

  @media all and (min-width: $mobile-max-size) {
    margin-left: -50%;
    width: 50%;
  }

  @media all and (min-width: $desktop-size) {
    width: calc(30% - 3rem);
    margin-left: 0%;
  }

  &__open {
    margin-left: 0;
  }

  h2 {
    a {
      color: #e4611d;
      font-size: 24px;
      @media screen and (min-width: 769px) { font-size: 28px; }
      @media screen and (min-width: 993px) { font-size: 30px; }
      font-weight: 600;
      text-decoration: none;
      text-transform: lowercase;
      letter-spacing: 0.94px;
      padding: 0 1.2rem;
    }
  }
  h3 {
    text-transform: lowercase;
    font-size: 16px;
    @media screen and (min-width: 769px) { font-size: 18px; }
    @media screen and (min-width: 993px) { font-size: 20px; }
    font-weight: 600;
    color: #262626;
    letter-spacing: 0.59px;
    line-height: 2em;
  }
}

.keystone-main {
  opacity: 1;
  width: 100%;
  max-width: 720px;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 0 1.2rem;
  @media all and (min-width: $desktop-size) {
    width: calc(75% - 30px);
    padding: 0;
  }

  h1 {
    color: #262626;
    font-size: 28px;
    text-align: center;
    @media screen and (min-width: 769px) {
      font-size: 30px;
      text-align: left;
    }
    font-weight: 600;
    letter-spacing: 0.94px;
  }
}

.separator-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.body-separator {
  background-color: rgba(151,151,151, 1);
  height: 1px;
  width: 260px;
  margin-top: 21px;
  margin-bottom: 21px;
}

.next-link {
  text-transform: lowercase;
  list-style: none;
  line-height: 1em;
  padding: 0.5em 0;
  font-size: 16px;
  font-weight: 400;
  color: #757575 !important;
  letter-spacing: 0.59px;
  @media screen and (min-width: 769px) { font-size: 18px; }
  @media screen and (min-width: 993px) { font-size: 20px; }
}

.caret-right-icon_nextLink {
  color: rgba(228,97,29, 1);
  font-size: 32px;
  margin-right: 10.75px;
  padding-top: 12px;
  @media screen and (width <= $mobile-max-size) {
    margin-right: 9.75px;
  }
}

.keystone__open {
  .keystone-main {
    opacity: 0.15;
    transition: opacity 0.2s ease-in-out;
  }
}

.keystone-index {
  background-color: #f2f2f2;
  padding: 1.2rem;
  max-width: 400px;

  .keystone-index-links {
    padding-left: 0;
    margin: 0;
    border-bottom: 1px solid #979797;
    padding-bottom: 38px;
    margin-bottom: 38px;
  }

  .keystone-index-sublinks {
    padding-left: 0;
    margin: 0;
    padding: 0;
    margin-top: 6px;
    margin-bottom: -14px;
  }

  .keystone-index-link {
    text-transform: lowercase;
    list-style: none;
    line-height: 1em;
    padding: 0.5em 0;
    font-size: 16px;
    @media screen and (min-width: 769px) { font-size: 18px; }
    @media screen and (min-width: 993px) { font-size: 20px; }
    a {
      text-transform: lowercase;
      font-weight: 400;
      color: #262626;
      letter-spacing: 0.59px;
      &:hover {
        color: #757575;
      }
      &.active {
        color: #757575;
      }
    }
  }

  .keystone-index-sublink {
    text-transform: lowercase;
    list-style: none;
    line-height: 1em;
    padding: 0.5em 0;
    font-size: 16px;

    &:before {
        content: '\2022 ';
        color: RGB(228, 97, 29);
        padding-right: 0.5em;
    }

    @media screen and (min-width: 769px) { font-size: 18px; }
    @media screen and (min-width: 993px) { font-size: 20px; }
    a {
      text-transform: lowercase;
      font-weight: 400;
      color: #262626;
      letter-spacing: 0.59px;
      &:hover {
        color: #757575;
      }
      &.active {
        color: #757575;
      }
    }
  }
}

.newsletter-section {
  border-bottom: 1px solid #979797;
  margin-bottom: 26px;
  padding-bottom: 38px;
}

.go-deeper-section {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.go-deeper-text {
  color: rgba(0,0,0,0.85);
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 0.89px;
  margin-bottom: -2px;
}

.go-deeper-link {
  display: flex;
  flex-direction: row;
  text-decoration: none;
}

.go-deeper-link img {
  width: 50px;
  height: 58px;
  padding-top: 8px;
}

.img-box {
  width: 260px;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.53px;
  line-height: 30px;
  text-decoration: underline;
  margin-left: 10px;
  background-color: #f2f2f2;
  top: 5px;
}

.img-box.blue {
  color: rgba(7,120,151,1);
}

.img-box.purple {
  color: rgba(140, 92, 165, 1);
}

.img-box.green {
  color: rgba(62, 140, 39, 1);
}

h1, h2 {
  margin: 32px 0;
  padding: 0;
}

.alert {
  position: relative;
  padding: .75rem 1.25rem;
  margin: 1rem 0 0 0;
  border: 1px solid transparent;
  border-radius: .25rem;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

// v-deep required as the content is rendered via v-html.
::v-deep {
  .keystone-main {
    .embed-container {
        position: relative;
        width: 100%;
        height: 0;
        padding-top: 56.25%;
        margin-bottom: 12px;
      }
      .embed-tool__content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    img {
      margin-bottom: 12px;
    }
    p {
      margin: 0 0 1.2em 0;
      font-size: 16px;
      @media screen and (min-width: 769px) { font-size: 20px; }
    }
    .c-post-body-section__quote-body {
      margin-bottom: 1rem;
    }
  }
}

.three-button-tab {
  margin-top: 40px;
  margin-bottom: -15px;
}
</style>
