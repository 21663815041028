<template>
  <ResponsivePage>
    <div class="t-project">
      <HeroImage alt="Triratna"
        :imgref="pageHeroImage.desktop"
        :imgSet="pageHeroImage.mobile + ' 548w, ' + pageHeroImage.tablet + ' 734w, ' + pageHeroImage.desktop + ' 2876w'"
        :imgSizes="'(max-width: 414px) 548px, (max-width: 768px) 734px, 2876px'"
      />
    </div>
    <SingleSlider
      v-if="slider1"
      :stories="buildSlides(slider1)"
      :title="slider1.name"
      :subtitle="slider1.subtitle"
      :titleHref="slider1.url"
    />
    <SingleSlider
      v-if="slider2"
      :stories="buildSlides(slider2)"
      :title="slider2.name"
      :subtitle="slider2.subtitle"
      :titleHref="slider2.url"
      slideTypeClass="slider-type__quotes"
    />
    <div class="t-project" id="find-a-centre"
        @click.prevent="redirectToFindCentre">
      <CentreSearchBar
        @search="centreSearch"
      />
    </div>
    <Slider
      class="tbco-live-slider section-top-border-grey shaded-section"
      :stories="tbcLiveStories"
      :itemsToShowDesktop="2.20"
      :itemsToShowTablet="1.33"
      :itemsToShowMobile="1.15"
      title="live meditations, classes, retreats, events"
      subtitle="see all live events"
      subtitleHref="/live/"
      :colouredTitles="true"
      :showPagination="true"
      />
    <div class="t-tbco">
      <NewsletterSignup
        :hidden="!showNewsletterSignup"
        @hide="hideNewsletter"
        @show="showNewsletter"
        title="get the triratna highlights newsletter"
        subtitle="new events and the best of triratna in your mailbox, once a week"
        buttonColor="green"
      />
    </div>
    <!-- insert podcast slider -->
    <div class="fba-section section-top-border-black shaded-section t-fba">
      <Slider
        :stories="fbaLatest"
        :itemsToShowDesktop="6"
        :itemsToShowSmallScreen="4"
        :itemsToShowLargeMobile="2"
        :itemsToShowTablet="3"
        :itemsToShowMobile="2"
        :itemsToSlideDesktop="6"
        :itemsToSlideSmallScreen="4"
        :itemsToSlideLargeMobile="2"
        :itemsToSlideTablet="3"
        :itemsToSlideMobile="2"
        title="classic intros to buddhism & meditation"
        icon="/sites/all/themes/tbc/images/fba_logo_lg.png"
        subtitleHref="/fba"
        subtitle="listen to more introductions"
        :colouredTitles="false"
        :squareMedia="true"
        :showPagination="true"
        :circularMedia="true"
        :talkPanels="true"
        :openLinksInNewTab="true"
        titleLineLimit=3
      >
      <template v-slot:placeholderBody>
        <div class="slide-image">
          <img src="https://www.freebuddhistaudio.com/images/default-square.jpg">
        </div>
      </template>
      </Slider>
    </div>
    <div class="news-section fba-section section-top-border-black shaded-section t-fba">
      <NewsPanel
        title="discover the great stream of practice"
        subtitle="buddhist community around the world, in real time"
        href="/community"
        :news="communityStream"
      />
    </div>
      <div class="t-project">
        <SocialBar title="the buddhist centre [social]"
                   subtitle="follow, subscribe, only connect"
                   theme="triratna"
        />
      </div>

    <div class="t-project" slot="footer">
      <FooterLinks title="the best of triratna online since 2013"
        subtitle="the best of triratna online since 2013"
        link="link"
        :menus="footerMenus"
        theme="triratna"
      />
    </div>

    <FullscreenModal v-if="centreSearchCriteria" @close="centreSearchCriteria = null">
    <div slot="header"><h2>Triratna Centres</h2></div>
      <div slot="body">
        <p class="search-criteria">Searching for: {{ centreSearchCriteria }}</p>
        <ul v-if="communityLinks && communityLinks.length" class="community-links">
          <li v-for="link in communityLinks" :key="link.id">
            <h3>{{ link.name }}</h3>
            <p v-if="link.tel">t. {{ link.tel }}</p>
            <p v-if="link.url">w. <a :href="link.url" target="_blank">{{ link.url }}</a></p>
          </li>
        </ul>
      </div>
      <div slot="footer">
        <button class="btn btn-default btn-close" @click="centreSearchCriteria = null">x</button>
      </div>
    </FullscreenModal>
  </ResponsivePage>
</template>

<script>
import axios from 'axios'
import communityLinksApi from '../api/community-links-api.js'
import { buildSlideBody } from '../api/sliders-api.js'
import ResponsivePage from './ResponsivePage'
import SingleSlider from '@dharmachakra/tbco-slider/src/components/SingleSlider'
import Slider from '@dharmachakra/tbco-slider/src/components/Slider'
import HeroImage from '@dharmachakra/tbco-shared-styles/src/components/HeroImage.vue'
import FooterLinks from '@dharmachakra/tbco-shared-styles/src/components/FooterLinks.vue'
import { NewsletterSignup } from '@dharmachakra/newsletter-signup-component'
import SocialBar from '@dharmachakra/tbco-shared-styles/src/components/SocialBar.vue'
import CentreSearchBar from '@dharmachakra/tbco-shared-styles/src/components/CentreSearchBar.vue'
import FullscreenModal from '@dharmachakra/tbco-shared-styles/src/components/FullscreenModal.vue'
import NewsPanel from '@dharmachakra/tbco-shared-styles/src/components/NewsPanel.vue'
import { fetchPageData } from '../api/browser-api.js'
import '@dharmachakra/newsletter-signup-component/dist/newsletter-signup-component.css'
import '@dharmachakra/tbco-panels/dist/tbco-panels.css'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faSquareFacebook, faInstagram, faYoutube, faSoundcloud } from '@fortawesome/free-brands-svg-icons'
library.add(faSquareFacebook, faInstagram, faYoutube, faSoundcloud)

export default {
  components: {
    ResponsivePage,
    SingleSlider,
    Slider,
    HeroImage,
    NewsletterSignup,
    SocialBar,
    CentreSearchBar,
    FullscreenModal,
    FooterLinks,
    NewsPanel
  },
  data () {
    return {
      pageHeroImage: {},
      foot1Menu: {},
      foot2Menu: {},
      foot3Menu: {},
      foot4Menu: {},
      foot5Menu: {},
      foot6Menu: {},
      canEdit: false,
      showNewsletterSignup: true,
      footerMenus: [],
      slider1: {},
      slider2: {},
      slider3: {},
      centreSearchCriteria: null,
      communityLinks: []
    }
  },
  created () {
    if (document.__TBCO__.app && document.__TBCO__.app.menus) {
      this.foot1Menu = document.__TBCO__.app.menus.foot1 || {}
      this.foot2Menu = document.__TBCO__.app.menus.foot2 || {}
      this.foot3Menu = document.__TBCO__.app.menus.foot3 || {}
      this.foot4Menu = document.__TBCO__.app.menus.foot4 || {}
      this.foot5Menu = document.__TBCO__.app.menus.foot5 || {}
      this.foot6Menu = document.__TBCO__.app.menus.foot6 || {}

      this.footerMenus = [
        this.formatFooterMenu(this.foot1Menu),
        this.formatFooterMenu(this.foot2Menu),
        this.formatFooterMenu(this.foot3Menu),
        this.formatFooterMenu(this.foot4Menu),
        this.formatFooterMenu(this.foot5Menu),
        this.formatFooterMenu(this.foot6Menu)
      ]
    }

    this.slider1 = document.__TBCO__.slider1
    this.slider2 = document.__TBCO__.slider2
    this.slider3 = document.__TBCO__.slider3

    axios.get('/api/v1/roles/hide%20newsletter%20signup')
      .then(response => {
        if (response.data.has_role) this.showNewsletterSignup = false
      }).catch(() => {
        // @todo - log error?
      })

    const pageData = fetchPageData()
    this.pageHeroImage = pageData.heroImage
  },
  computed: {
    communityStream () {
      const stream = document.__TBCO__.communityStream
      if (!stream) return []
      return stream
        .map(story => Object.assign({ is_iframe: story.body.includes('iframe-feature-wrapper') }, story))
    },
    fbaLatest() {
      const stream = document.__TBCO__.fbaLatest
      const fbaBaseUrl = document.__TBCO__.fbaBaseUrl
      if (!stream) return []
      return stream
        .map(story => {
          let image = (story.speaker_image) ? fbaBaseUrl + story.speaker_image : fbaBaseUrl + '/images/default-square.jpg'
          if (story.image) {
            image = fbaBaseUrl + story.image
          }

          return Object.assign(story, {
            is_iframe: false,
            subtitle: story.speaker,
            link: fbaBaseUrl + '/audio/details?num=' + story.cat_num,
            image: image,
            om_only: story.om === '1'
          })
        })
    },
    tbcLiveStories () {
      const section = document.__TBCO__.frontpageStoriesSections
        .filter(section => section.label === 'tbco live')
      const stories = (section.length > 0) ? section[0].stories : []
      if (!stories) return []
      return stories
        .map(story => Object.assign({ is_iframe: story.body.includes('iframe-feature-wrapper') }, story))
    }
  },
  methods: {
    redirectToFindCentre() {
      // @todo - remove this call above when we have finished the
      // centreSearch modal and data below.
      window.location.href = '/text/triratna-around-world'
    },
    centreSearch(data) {
      communityLinksApi.fetchAll(null, 10000, data)
        .then(links => {
          this.communityLinks = links['community-links']
        })
        .catch(err => {
          this.communityLinks = []
          console.error(err)
        })
        .finally(() => {
          this.centreSearchCriteria = data
        })
    },
    buildSlides(slider) {
      if (!slider.json) {
        return []
      }

      return buildSlideBody(slider.json, slider.random_order)
    },
    formatFooterMenu(menu) {
      menu.title = menu.name

      if (!menu.menu_items) {
        return menu
      }

      menu.links = menu.menu_items.map((item) => {
        item.label = item.name
        item.href = item.url
        return item
      })

      return menu
    },
    hideNewsletter () {
      axios.put('/api/v1/roles/hide%20newsletter%20signup')
        .then(response => {
          if (response.data.ok) this.showNewsletterSignup = false
        })
    },
    showNewsletter () {
      axios.delete('/api/v1/roles/hide%20newsletter%20signup')
        .then(response => {
          if (response.data.ok) this.showNewsletterSignup = true
        })
    }
  }
}
</script>

<style scoped lang="scss">
.community-section {
  padding: 0 20px 52px 20px;
}

.t-project {
  .c-channel-hero__image-container {
    margin-top: 1.75rem;
  }
}

.community-links {
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    margin-bottom: 1rem;
  }
}

.search-criteria {
  margin-bottom: 1rem;
}

::v-deep {
  .modal-container {
    width: 60rem;
  }
}
</style>
