<template>
  <ResponsivePage>
    <div class="search-results">
      <form @submit.prevent="performSearch()" class="search-form">
        <p>
          search results for
          <input type="text" v-model="form_criteria" placeholder="enter criteria" />
          <button class="btn btn-default">search</button>
        </p>
        <p v-if="!loading" class="search-results-info"><b>{{ total }} results found.</b> Showing page {{ currentPage }} of {{ noOfPages }} with {{ resultsPerPage }} per page.</p>
      </form>

      <Loading v-if="loading" :loading="loading" />

      <div v-else>
        <div v-if="allSelectedFilters.length" class="selected-filters">
          <h3>Selected filters</h3>
          <ul class="filters-filter-options">
            <li v-for="filter in allSelectedFilters" :key="filter.index + '-' + filter.key" class="filters-filter-option filters-filter-option__selected" @click="removeFilter(filter.index, filter.key)">[{{ filter.index }}] {{ filter.key }} <span class="icon-remove">x</span></li>
            <li class="filters-filter-option__clear-all"><a href="#clear-all-selected-filters" @click.prevent="clearSelectedFilters">clear all</a></li>
          </ul>
        </div>

        <div class="results-page">
          <div class="filters results-page-sidebar" v-if="response.response && response.response.aggregations">
            <p>filter results <a v-if="!showFilters" href="#show-filters" @click.prevent="showFilters = true">show options</a><a v-else href="#hide-filters" @click.prevent="showFilters = false">hide options</a></p>
            <div v-show="showFilters" v-for="(aggs, index) in response.response.aggregations" :key="index">
              <h4>{{ index }}</h4>
              <ul class="filters-filter-options">
                <li v-for="bucket in aggs.buckets" :key="bucket.key" class="filters-filter-option" @click="selectFilter(index, bucket.key)">
                  <input type="checkbox" :checked="hasFilter(index, bucket.key)" />
                  {{ bucket.key }} ({{ bucket.doc_count }})
                </li>
                <li v-if="!aggs.buckets.length">none</li>
              </ul>
            </div>
          </div>

          <div class="filters sort-by-links results-page-sidebar" v-if="response.response">
            <p>view by: <button v-for="sortLink in sortByLinks" :key="sortLink.value" type="button" @click.prevent="selectSortBy(sortLink.value)" :class="{ 'sort-option': true, 'sort-option__active': sortBy === sortLink.value }">{{ sortLink.label }}</button></p>
          </div>

          <div v-if="userResults.length > 0" class="stream results-page-main user-results-container">
            <h3>users</h3>
            <div class="user-results">
              <div v-for="user in userResults" class="user-results-result" :key="user.title">
                <a :href="user.link" :title="user.title + ' user account'" target="_blank">
                  <img :src="user.image" :title="user.title + ' user image'" />
                  <p>{{ user.title }}</p>
                  <p>{{ user.email }}</p>
                </a>
              </div>
            </div>
          </div>

          <div v-if="placeResults.length > 0" class="stream results-page-main user-results-container">
            <h3>places</h3>
            <div class="user-results">
              <div v-for="place in placeResults" class="keystone-results-result" :key="place.id">
                <img v-if="place.image" :src="place.image.path" :title="place.name + ' image'" />
                <img v-else src="/assets/img/tbco-logo-60.png" :title="place.name + ' image'" />
                <p>{{ place.name }}</p>
                <a v-if="place.url" :href="place.url" :title="place.name" target="_blank">
                  <p>website</p>
                </a>
              </div>
            </div>
          </div>

          <div v-if="keystoneResults.length > 0" class="stream results-page-main user-results-container">
            <h3>keystones</h3>
            <div class="user-results">
              <div v-for="keystone in keystoneResults" class="keystone-results-result" :key="keystone.id">
                <a :href="'/' + keystone.url" :title="keystone.title" target="_blank">
                  <img v-if="keystone.marquee_image" :src="keystone.marquee_image.path" :title="keystone.title + ' image'" />
                  <img v-else src="/assets/img/tbco-logo-60.png" :title="keystone.title + ' image'" />
                  <p>{{ keystone.title }}</p>
                </a>
              </div>
            </div>
          </div>

          <div v-if="results.length > 0" class="stream results-page-main">
            <template v-for="post in results">
            <ChannelPostSummary
              v-if="post.type === 'channel' || post.type === 'group'"
              :key="post.nid"
              class="stream-post"
              :imgref="post.heroImage"
              :username="post.title"
              colorClass="green"
              :title="post.title"
              :titleurl="post.path"
              :author="post.title"
              :date="post.created"
              :content="post.content"
              :isOm="post.omOnly"
              @open="loadPost(post)"
              >
              <div slot="content-header" class="media-holder">
                <div v-if="post.heroImage" class="media-gallery responsive responsive-16by9">
                  <img :src="post.heroImage" />
                </div>
              </div>
            </ChannelPostSummary>

            <ChannelPostSummary
              v-if="post.type === 'content'"
              class="stream-post"
              :key="post.nid"
              :imgref="(!showContext) ? post.author.picture : ((post.channel) ? post.channel.coverImage : post.author.picture)"
              :username="(!showContext) ? post.author.name : ((post.channel) ? post.channel.title : post.author.name)"
              colorClass="green"
              :title="post.title"
              :subtitle="post.subtitle"
              :titleurl="post.path"
              :author="post.author.name"
              :date="post.created"
              :content="post.content"
              @open="loadPost(post)"
              >
              <div slot="content-header" v-if="post.media.length > 0" class="media-gallery">
                <div v-if="post.media[0].mimeType === 'text/html'" v-html="post.media[0].metadata.html" class="media-embed"></div>
                <img v-if="isImageMime(post.media[0].mimeType)" :src="post.media[0].source" class="content-image" />
              </div>
            </ChannelPostSummary>
            </template>
          </div>
          <div v-else class="stream results-page-main">
            <p>No results found.</p>
          </div>
        </div>

        <PageSelectControls :noOfPages="noOfPages" :currentPage="currentPage" @pageSelected="selectPage" :queryParams="queryParams" />
      </div>

      <FullscreenModal v-if="postOpened" @close="loadPost(null)">
      <div slot="header"></div>
        <div slot="body">
          <FullPost v-bind="postOpened" />
        </div>
        <div slot="footer">
          <button class="btn btn-default btn-close" @click="loadPost(null)">x</button>
        </div>
      </FullscreenModal>

    </div>
  </ResponsivePage>
</template>

<script>
import ResponsivePage from './ResponsivePage'
import PageSelectControls from '../components/organisms/PageSelectControls.vue'
import Loading from '../components/Loading.vue'
import FullPost from '../components/FullPost.vue'
import ChannelPostSummary from '@dharmachakra/tbco-shared-styles/src/components/ChannelPostSummary'
import FullscreenModal from '@dharmachakra/tbco-shared-styles/src/components/FullscreenModal.vue'
import appApi from '../api/app-api.js'
import searchApi from '../api/search-api.js'
import { isImageMime } from '../api/media-api.js'
import { updateURL } from '../api/browser-api.js'

export default {
  components: {
    ResponsivePage,
    PageSelectControls,
    Loading,
    ChannelPostSummary,
    FullscreenModal,
    FullPost
  },
  props: {
    criteria: String,
    startingTotal: Number,
    startingCurrentPage: Number,
    resultsPerPage: Number,
    startingResults: {
      type: Array,
      default() {
        return []
      }
    },
    pageNo: {
      type: Number,
      default: 1
    },
    pages: {
      type: Number,
      default: 1
    },
    startingFilters: {
      type: Object,
      default() {
        return {}
      }
    },
    startingResponse: {
      type: Object,
      default() {
        return {}
      }
    },
    showContext: {
      type: Boolean,
      default: false
    },
    startingSortBy: {
      type: String,
      default: 'newest_first'
    },
    users: {
      type: Array,
      default() {
        return []
      }
    },
    keystones: {
      type: Array,
      default() {
        return []
      }
    },
    places: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      loading: false,
      showFilters: false,
      results: this.startingResults,
      response: {
        response: this.startingResponse
      },
      total: this.startingTotal,
      noOfPages: this.pages,
      currentPage: this.startingCurrentPage,
      form_criteria: this.criteria,
      selectedFilters: this.startingFilters,
      sortBy: this.startingSortBy,
      sortByLinks: [
        {
          value: 'relevance',
          label: 'relevance'
        },
        {
          value: 'newest_first',
          label: 'most recent first'
        },
        {
          value: 'oldest_first',
          label: 'oldest first'
        }
      ],
      postOpened: null,
      userResults: this.users,
      keystoneResults: this.keystones,
      placeResults: this.places
    }
  },
  computed: {
    allSelectedFilters() {
      let filters = []
      let selectedFilters = this.selectedFilters

      for (let index in selectedFilters) {
        selectedFilters[index].forEach(filter => {
          filters.push({
            index: index,
            key: filter.key
          })
        })
      }

      return filters
    },
    queryParams() {
      let params = {}

      if (this.form_criteria) {
        params.criteria = this.form_criteria
      }

      if (this.currentPage) {
        params.page = this.currentPage
      }

      if (this.postOpened) {
        params.openedPost = this.postOpened.nid
      }

      if (this.sortBy) {
        params.sort = this.sortBy
      }

      for (let index in this.selectedFilters) {
        params[index] = this.selectedFilters[index].map(filter => filter.key).join('|')
      }

      return params
    }
  },
  mounted() {
    let existingScript = document.querySelector('script[src="//e.issuu.com/embed.js"]')
    if (!existingScript) {
      let recaptchaScript = document.createElement('script')
      recaptchaScript.setAttribute('src', '//e.issuu.com/embed.js')
      document.head.appendChild(recaptchaScript)
    }
  },
  methods: {
    clearSelectedFilters() {
      this.selectedFilters = {}
      this.search(this.currentPage)
    },
    hasFilter(index, name) {
      if (!this.selectedFilters[index]) {
        return false
      }

      return (this.selectedFilters[index].filter(filter => filter.key === name).length > 0)
    },
    selectSortBy(name) {
      this.sortBy = name
      this.performSearch()
    },
    selectFilter(index, name) {
      if (this.hasFilter(index, name)) {
        return this.removeFilter(index, name)
      }

      let selectedFilters = Object.assign({}, this.selectedFilters)

      if (!selectedFilters[index]) {
        selectedFilters[index] = []
      }

      selectedFilters[index].push({
        index: index,
        key: name
      })

      this.selectedFilters = selectedFilters
      this.performSearch()
    },
    removeFilter(index, name) {
      let selectedFilters = Object.assign({}, this.selectedFilters)

      if (!selectedFilters[index]) {
        console.error('could not find index.')
        return
      }

      selectedFilters[index] = selectedFilters[index].filter(filter => filter.key !== name)

      this.selectedFilters = selectedFilters
      this.performSearch()
    },
    loadPost(post) {
      this.postOpened = post
      this.updateURL()
    },
    updateURL() {
      updateURL(this.queryParams, '/search/' + this.form_criteria)
    },
    isImageMime(mimeType) {
      return isImageMime(mimeType)
    },
    performSearch () {
      this.currentPage = 1
      this.search(this.currentPage)
    },
    search(no) {
      this.loading = true
      this.response = {}
      this.updateURL()
      searchApi.search(no, this.resultsPerPage, this.form_criteria, this.queryParams)
        .then(data => {
          this.response = data
          this.results = data.results
          this.total = data.total
          this.noOfPages = data.pages
          this.currentPage = data.current_page
          this.userResults = data.users || []
          this.keystoneResults = data.keystones || []
          this.placeResults = data.places || []
        })
        .catch(err => {
          appApi.addMessage('danger', err.msg || err)
        })
        .finally(() => {
          this.loading = false
          this.searchStarted = true
        })
    },
    selectPage(no) {
      this.currentPage = no
      this.search(no)
    }
  }
}
</script>

<style scoped lang="scss">
.search-results {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.6px;
  line-height: 30px;

  a {
    color: #000;
    text-decoration: underline;
  }

  h4 {
    margin-bottom: 0.25rem;
  }
}

.search-results-criteria {
  font-weight: 700;
}

.search-results-info {
  margin: 1rem 0;
}

.search-results-pagination {
  margin: 1rem 0 3rem 0;

  li {
    display: inline-block;
    margin: 0 1rem;
  }
}

.search-results-results {
  margin: 1rem 0;

  li {
    margin: 0.5rem 0;
  }
}

// @todo - Copied from ChannelPage. Move to central place/component.
.stream-post {
  padding-bottom: 1rem;
  margin-bottom: 2em;
  border-bottom: 2px solid #999;

  &:last-child {
    border-bottom: none;
  }
}

.channel-description {
  margin-bottom: 3rem;
}

.stream, .channel-description, .load-more {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 26px;
  letter-spacing: 0.77px;

  a {
    color: #000;
  }

  a:hover {
    text-decoration: underline;
  }

  a:active {
    color: #000;
  }

  a:visited {
    color: #000;
  }
}

.channel-menu {
  padding: 1rem 0;
  border-bottom: 1px solid #979797;
  margin-bottom: 3em;
}

.load-more {
  margin: 4rem 0;
  text-align: center;
}

.btn-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  border: 0;
  background: none;
  font-size: 1em;
  font-weight: 600;
  &:hover {
    cursor: pointer;
  }
}

.view-full-post {
  position: absolute;
  top: 1.2rem;
  right: 3rem;
  color: #000;
}

.channel-stream {
  min-height: 20rem;
}

.media-gallery {
  margin-bottom: 1em;
}

::v-deep {
  .modal-mask {
    background-color: unset;
  }

  .modal-container {
    position: relative;
    max-width: 1440px;
    width: 98vw;
    max-height: 98vh;
    height: 98vh;
    border-radius: 10px;
  }

  .iframe-feature-wrapper iframe, .soundcloud-feature-wrapper iframe {
    width: 50%;
    height: 325px;
    @media screen  and (width < 769px) {
      width: 90%;
    }
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .iframe-feature-wrapper, .soundcloud-feature-wrapper {
    padding-top: 34%;
    @media screen  and (width < 500px) {
      padding-top: 64%;
    }
  }

  .content-image {
    height: 325px;
    padding-bottom: 20px;
    @media screen  and (width < 500px) {
      height: 250px;
    }
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.filters-filter-option {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #eee;
  margin: 0 0.5rem 0.5rem 0;
  cursor: pointer;

  &__selected {
    display: inline-block;
    background-color: lightgreen;
    border: 1px solid green;
    margin-right: 0.5rem;
  }

  &__clear-all {
    display: inline-block;
  }
}

.results-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &-sidebar {
    padding-bottom: 1rem;
    margin-bottom: 2rem;
    border-bottom: 1px solid #979797;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.selected-filters {
  margin-bottom: 1rem;
  padding-left: 20px;
  padding-right: 20px;
}

.sort-option {
  text-decoration: underline;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;

  &::after {
    content: '|';
    display: inline-block;
    margin: 0 0.5rem;
  }

  &:last-child::after {
    content: unset;
  }

  &__active {
    text-decoration: none;
  }
}

.results-page-sidebar {
  margin-bottom: 1rem;
}

.sort-by-links {
  margin-bottom: 2rem;
}

.search-form {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
  border-bottom: 1px solid #979797;
  padding-bottom: 1rem;
  padding-left: 20px;
  padding-right: 20px;

  @media screen and (min-width: 1050px) {
    flex-direction: row;
    align-items: center;
  }

  p {
    margin: 0;
  }

  input[type="text"] {
    padding: 0 0.5rem;
    margin-right: 0.5rem;
  }

  button {
    margin-right: 1rem;
  }
}

.user-results-container {
  margin-bottom: 2rem;
}

.user-results {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  gap: 1rem;
}

.user-results-result {
  flex-shrink: 0;
  width: 164px;
  font-size: 15px;
  margin: 1rem 0;

  img {
    border-radius: 50%;
    width: 85px;
  }

  a {
    display: block;
    position: relative;
    overflow: hidden;
  }

  p {
    word-break: break-word;
    hyphens: manual;
  }
}

.keystone-results-result {
  flex-shrink: 0;
  width: 164px;
  font-size: 15px;
  margin: 1rem 0;

  a {
    display: block;
    position: relative;
    overflow: hidden;
  }

  p {
    word-break: break-word;
    hyphens: manual;
  }
}
</style>
