<template>
  <ResponsivePage>
    <h1>Admin Dashboard</h1>
    <p>
    <ul>
      <li><a href="/user" title="My user account">Go to my user account</a></li>
      <li><a href="/admin/" title="Drupal Admin area">Go to drupal admin area</a></li>
      <li><a href="/" title="Frontpage">Go to the frontpage/home</a></li>
    </ul>
    </p>
  </ResponsivePage>
</template>

<script>
import ResponsivePage from './ResponsivePage'

export default {
  components: {
    ResponsivePage
  }
}
</script>

<style scoped lang="scss">
.page {
  width: 1440px;
  max-width: 100%;
  margin: 1rem auto;
}
</style>
