<template>
  <div>
    <div class="button-container">
      <a :href="button1Link" :class="['square-button', {'active1': isCurrentPage(button1Link)}, {'buddhism': !isCurrentPage(button1Link)}]">buddhism</a>
      <a :href="button2Link" :class="['square-button', {'active2': isCurrentPage(button2Link)}, {'meditation': !isCurrentPage(button2Link)}]">meditation</a>
      <a :href="button3Link" :class="['square-button', {'active3': isCurrentPage(button3Link)}, {'triratna': !isCurrentPage(button3Link)}]">triratna</a>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@dharmachakra/tbco-shared-styles/src/assets/scss/partials/_variables.scss';
.button-container {
  display: flex;
  justify-content: center;
  gap: 1px;

  @media screen and (min-width: 1280px) {
    border-bottom: 1px solid #979797;
    padding-bottom: 40px;
  }
}

.square-button {
  width: 160px;
  height: 40px;
  border: none;
  border-radius: 0;
  cursor: pointer;
  text-decoration: none; /* Remove underline for links */
  line-height: 40px; /* Vertically center text within the buttons */
  padding: 0 10px; /* Add some horizontal padding to the buttons */

  color: white;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0.94px;
  text-align: center;
  flex-grow: 0;

  @media screen and (max-width: $tablet-max-size) {
    width: 133px;
    font-size: 18px;
    letter-spacing: 0.56px;
  }
  @media screen and (max-width: $mobile-max-size) {
    width: 107px;
    font-size: 16px;
    letter-spacing: 0.5px;
  }
}

.buddhism {
  background-color: #E4611D;
}

.active1 {
  background-color: white;
  border: 1.5px solid #E4611D;
  color: #E4611D;
}

.meditation {
  background-color: #00ADEF;
}

.active2 {
  background-color: white;
  border: 1.5px solid #00ADEF;
  color: #00ADEF;
}

.triratna {
  background-color: #5ABA83;
}

.active3 {
  background-color: white;
  border: 1.5px solid #5ABA83;
  color: #5ABA83;
}
</style>

<script>
export default {
  props: {
    button1Link: {
      type: String,
      required: true
    },
    button2Link: {
      type: String,
      required: true
    },
    button3Link: {
      type: String,
      required: true
    }
  },
  methods: {
    isCurrentPage(link) {
      const currentPageUrl = window.location.pathname
      return currentPageUrl.endsWith(link)
    }
  }
}
</script>
