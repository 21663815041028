<template>
  <ResponsivePage>
    <div class="content error-page">
      <h2>{{ titleOverride ? titleOverride : title }}</h2>
      <div v-html="messageOverride ? messageOverride : message"></div>
    </div>
  </ResponsivePage>
</template>

<script>
import ResponsivePage from './ResponsivePage'

export default {
  components: {
    ResponsivePage
  },
  props: {
    title: {
      type: String,
      default: "There's been a problem displaying this page"
    },
    message: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      titleOverride: undefined,
      messageOverride: undefined
    }
  },
  created() {
    if (document.__TBCO__.error) {
      this.titleOverride = document.__TBCO__.error.title
      this.messageOverride = document.__TBCO__.error.message
    }
  }
}
</script>

<style scoped lang="scss">
.content {
  font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif;
  padding: 0 10px;
  @media (min-width: 750px) {
    padding: 0 20px;
  }
}
h2 {
  margin-bottom: 1.5em;
}
.error-page {
  max-width: 920px;
  margin: 4em auto;
}

::v-deep p {
  margin-bottom: 1rem;
}
</style>
