export function shareSomethingLinks(canPostContent, nid) {
  if (!canPostContent) {
    return []
  }

  return [
    { href: '/node/add/group-text-post/' + nid, label: 'write' },
    { href: '/node/add/group-resources/' + nid, label: 'resources' },
    { href: '/node/add/group-talk/' + nid, label: 'audio' },
    { href: '/node/add/group-video-embed/' + nid, label: 'video' },
    { href: '/node/add/group-image-post/' + nid, label: 'pictures' }
  ]
}

export function channelFollowText(nodeType, isMember) {
  if (nodeType.startsWith('group')) {
    return isMember ? 'leave this group' : 'join this group'
  } else {
    return isMember ? 'unfollow this channel' : 'follow this channel'
  }
}
