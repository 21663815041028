<template>
  <div class="podcast-player" :class="'podcast-player-' + podcast">
    <div class="podcast-player-header fba-podcast">
      <h2>{{ title }}</h2>
      <p><a :href="link">{{ linkText }}</a> <span class="plus">+</span></p>

      <div v-if="secondaryLink">
        <a :href="secondaryLink" class="secondary-link">{{ secondaryLinkText }}</a>
      </div>
    </div>

    <div class="audioboom-podcast" :class="'audioboom-player-' + podcast">
      <iframe v-if="podcast === 'tbco'" width="100%" height="400" src="https://embeds.audioboom.com/publishing/playlist/v4?boo_content_type=channel&amp;data_for_content_type=4929068&amp;image_option=small" style="background-color: transparent; display: block; padding: 0; width: 100%; max-width: 700px;" frameborder="0" allowtransparency="allowtransparency" scrolling="no" title="Audioboom player" allow="autoplay" sandbox="allow-downloads allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"></iframe>
      <iframe v-if="podcast === '50voices'" width="100%" height="400" src="https://embeds.audioboom.com/channels/3137650/embed/hero" style="background-color: transparent; display: block; padding: 0; width: 100%; max-width: 700px;" frameborder="0" allowtransparency="allowtransparency" scrolling="no" title="Audioboom player" allow="autoplay" sandbox="allow-downloads allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"></iframe>
    </div>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCog } from '@fortawesome/free-solid-svg-icons'
library.add(faCog)

export default {
  components: {
  },
  props: {
    podcast: {
      type: String,
      default: 'tbco'
    },
    title: String,
    link: String,
    linkText: String,
    secondaryLink: String,
    secondaryLinkText: String,
    bgColor: {
      type: String,
      default: 'f3f3f3'
    }
  },
  created() {
    this.loading = true
  },
  data() {
    return {
      player: 'libsyn', // 'libsyn' or 'custom'
      libsynSrc: '//play.libsyn.com/embed/destination/id/18108/height/412/theme/modern/size/large/thumbnail/yes/custom-color/f3f3f3/playlist-height/200/direction/backward',
      talk: null,
      dharmabytesPodcast: null
    }
  },
  computed: {
  }
}
</script>

<style scoped lang="scss">
.podcast-player {
  .audioboom-podcast {
    iframe {
      max-width: 100% !important;
    }
  }
  box-sizing: border-box;
  color: #fff;
  .podcast-player-header {
    padding: 1.5rem;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.25rem;
    display: flex;
    align-items: baseline;
    h2 {
      font-size: 3.75rem;
      margin: 0;
      padding-right: 20px;
      color: #000;
    }
    p {
      margin: 0;
      flex-grow: 1;
      position: relative;
      top: -3px;
    }
    a {
      color: #000;
    }
  }
}
a {
  color: #fff;

  &:hover {
    color: #fff;
  }
}

.podcast-player-50voices {
  .podcast-player-header {
    h2 {
      font-size: 26px;
      letter-spacing: 0.81px;
      white-space: unset !important;
    }

    .secondary-link {
      color: RGB(0, 106, 163);
      font-weight: 600;
    }
  }
}

/* Intermim responsive styles */
@media all and (max-width: 1439px) {
  .podcast-player {
    .podcast-player-header {
      font-size: 1rem;
      h2 {
        font-size: 2.5rem;
        white-space: nowrap;
      }
      p {
      }
    }
  }
}
@media all and (max-width: 1000px) {
  .podcast-player {
    .podcast-player-header {
      font-size: 1rem;
      display: block;
      h2 {
        font-size: 2.5rem;
        flex-grow: 1;
        white-space: nowrap;
      }
      p {
        flex-grow: 0;
        top: 0;
      }
    }
  }
}
/* Intermim responsive styles */
@media all and (max-width: 767px) {
  .podcast-player {
    .podcast-player-header {
      display: block;
      h2 {
        font-size: 2rem;
        flex-grow: 1;
        white-space: nowrap;
      }
      p {
        flex-grow: 0;
        top: 0;
      }
    }
  }
}

.plus {
        color: #e4611d;
        font-family: 'Source Sans Pro', sans-serif;
        letter-spacing: 0.56pt;
        font-size: 26px;
        font-weight: 700;
        flex-wrap: nowrap;
        position: relative;
        top: 2px;
        left: 8px;
    }
</style>
