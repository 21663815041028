<template>
  <ResponsivePage>
    <div :class="{ 't-project': (nodeType === 'projects'), 't-group' : (nodeType === 'group') }">
      <ChannelHero v-if="channel" :title="channel.title"
                :alt="channel.title"
                :imgref="channel.heroImage"
                :isOm="channel.omOnly"
                :subtitle="channelFollowTextComputed"
                :subtitleURL="channelFollowLink"
                :shareSomethingLinks="shareSomethingLinksComputed"
                :showShareLink=true
                :join="!isMember"
      />
    </div>

    <div class="page">
      <div class="page-main">
        <div v-html="html"></div>
      </div>
    </div>
  </ResponsivePage>
</template>

<script>
import ResponsivePage from './ResponsivePage'
import ChannelHero from '../components/ChannelHero.vue'
import { fetchPageData } from '../api/browser-api.js'
import { channelFollowText, shareSomethingLinks } from '../channel-helpers.js'

export default {
  components: {
    ResponsivePage,
    ChannelHero
  },
  props: {
    heroImage: String,
    channel: Object,
    html: String,
    nid: String,
    channelFollowLink: String,
    isMember: {
      type: Boolean,
      default: false
    },
    nodeType: {
      type: String,
      default: 'group'
    }
  },
  data() {
    return {
      pageHeroImage: {}
    }
  },
  created() {
    if (this.marqueeImage) {
      this.pageHeroImage = {
        desktop: this.marqueeImage.path,
        tablet: this.marqueeImage.path,
        mobile: this.marqueeImage.path
      }
    } else {
      const pageData = fetchPageData()
      this.pageHeroImage = pageData.heroImage
    }
  },
  computed: {
    shareSomethingLinksComputed() {
      return shareSomethingLinks(true, this.nid)
    },
    channelFollowTextComputed() {
      return channelFollowText(this.nodeType, this.isMember)
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/www/sites/all/themes/tbc/scss/_variables.scss";
@import "@dharmachakra/tbco-shared-styles/src/assets/scss/partials/_variables.scss";

.page-main {
  width: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 0 1.2rem;
}

::v-deep .page {
  font-family: 'Source Sans Pro', sans-serif;

  p, ul, ol {
    font-size: 20px;
    letter-spacing: 0.59px;
    @media screen and (max-width: $mobile-max-size) {
      font-size: 16px;
      letter-spacing: 0.39px;
    }
  }

  label {
    font-size: 22px;
    font-weight: 600;
    letter-spacing: 0.59px;
    @media screen and (max-width: $mobile-max-size) {
      font-size: 16px;
      letter-spacing: 0.39px;
    }
  }

  .ckeditor_links, .fieldset-wrapper, .fieldset-legend, .button, .description {
    font-size: 20px !important;
    letter-spacing: 0.59px !important;
    @media screen and (max-width: $mobile-max-size) {
      font-size: 16px !important;
      letter-spacing: 0.39px !important;
    }
  }

  .description {
    padding-top: 2px;
  }

  a {
    color: #000;
  }
}

::v-deep button,
::v-deep input[type=submit],
::v-deep .btn,
::v-deep input.form-submit,
::v-deep .form-actions a,
::v-deep .form-actions a:visited,
::v-deep .button,
::v-deep a.button,
::v-deep a.button:visited {
  font-size: 20px !important;
  padding: 1px 1em !important;
  letter-spacing: 0.59px;
  @media screen and (max-width: $mobile-max-size) {
    font-size: 16px !important;
    letter-spacing: 0.39px !important;
  }
}

h1, h2 {
  margin: 30px 0;
  padding: 0;
}
</style>
