<template>
  <ResponsivePage>
    <div :class="{ 't-project': (nodeType === 'projects'), 't-group' : (nodeType === 'group'), nomenu: !showMenu }">
      <ChannelHero
                :title="title"
                :alt="title"
                :subtitle="channelFollowTextWrapper"
                :imgref="heroImage"
                :subtitleURL="channelFollowLink"
                :shareSomethingLinks="shareSomethingLinksWrapper"
                :showShareLink="canPostContent"
                :join="!isMember"
                :isOm="omOnly"
                :userCanPost="userCanPost"
      />
    </div>

    <div v-if="showMenu" class="channel-menu" :class="(nodeType === 'projects') ? 't-project' : 't-group'">
      <ChannelMenu
        :links="channelLinks"
        :moreLinks="moreChannelLinks"
        :filters="channelFilters"
        :activeFilter="activeFilter"
        :activeLink="activeChannelLink"
        :showSearchIcon="true"
        :showAdminMenu="showChannelAdminMenu"
        :showListLink="(activeChannelLink !== 'groups' && activeChannelLink !== 'about') && showChannelListLink"
        :showGridLink="(activeChannelLink !== 'groups' && activeChannelLink !== 'about') && showChannelGridLink"
        :showBellLink="showChannelBellLink"
        :useMoreLinks="useMoreLinks"
        :query="searchQuery"
        :searchActive="searchActive"
        @selected="changePage"
        @search="data => { this.activeFilter = data.filter; this.searchQuery = data.query; updateURL(); loadPosts(); this.activeChannelLink = 'latest' }"
        @activateListView="() => this.contentView = 'list'"
        @activateGridView="() => this.contentView = 'magazine'"
        :moreText="moreText"
      />
    </div>

    <div v-if="activeChannelLink === 'about'">
      <component :is="AboutPage"
                 :content="content"
                 :title="title"
                 :coverImage="coverImage"
                 :showCommunityGuidelines="showCommunityGuidelines" />
    </div>

    <div v-show="display === 'latest' || activeChannelLink === 'latest' || activeChannelLink === 'groups' || activeChannelLink === 'shabda' || activeChannelLink === 'tags'" class="channel-stream">
      <FullscreenModal v-if="postOpened" @close="loadPost(null)">
      <div slot="header"></div>
        <div slot="body">
          <FullPost v-bind="postOpened" />
        </div>
        <div slot="footer">
          <button class="btn btn-default btn-close-top" @click="loadPost(null)">x</button>
          <button class="btn btn-default btn-close-bottom" @click="loadPost(null)">x</button>
        </div>
      </FullscreenModal>

      <div v-if="posts.length > 0 && streamType === 'posts'" class="stream">
        <template v-if="contentView === 'magazine'">
          <div class="magazine">
            <div class="magazine-content" v-for="post in posts" :key="post.id">
              <article>

                <div v-if="post.type === 'channel' || post.type === 'group'" class="responsive responsive-16by9">
                  <a :href="post.path" :title="post.title"><img :src="post.coverImage" :title="post.title + ' cover image'" /></a>
                </div>

                <div v-if="post.media && post.media.length > 0" class="responsive responsive-16by9">
                  <div v-if="post.media[0].mimeType === 'text/html'" v-html="post.media[0].metadata.html" class="media-embed" :class="{ 'soundcloud-feature-wrapper': post.media[0].provider === 'soundcloud' }"></div>
                  <a v-if="isImageMime(post.media[0].mimeType)" @click.prevent="loadPost(post)" :href="post.path" :title="post.title"><img v-if="isImageMime(post.media[0].mimeType)" :src="post.media[0].source" /></a>
                </div>

                <a v-if="post.type === 'channel' || post.type === 'group'" :href="post.path" :title="post.title" class="magazine-title">
                  <h1>{{ post.title }}</h1>
                  <h2 v-if="post.subtitle">{{ post.subtitle }}</h2>
                </a>

                <a v-else @click.prevent="loadPost(post)" :href="post.path" :title="post.title" class="magazine-title">
                  <h1>{{ post.title }}</h1>
                  <h2 v-if="post.subtitle">{{ post.subtitle }}</h2>
                </a>
              </article>
            </div>
          </div>
        </template>
        <template v-else>
          <template v-for="post in posts">
          <ChannelPostSummary
            v-if="post.type === 'channel' || post.type === 'group'"
            :key="post.nid"
            class="stream-post"
            :imgref="post.heroImage"
            :username="post.title"
            colorClass="green"
            :title="post.title"
            :titleurl="post.path"
            :author="post.title"
            :date="post.created"
            :content="post.content"
            :isOm="post.omOnly"
            @open="loadPost(post)"
            >
            <div slot="content-header" class="media-holder">
              <div v-if="post.heroImage" class="media-gallery responsive responsive-16by9">
                <img :src="post.heroImage" />
              </div>
            </div>
          </ChannelPostSummary>
          <ChannelPostSummary
            v-if="post.type === 'content'"
            :key="post.nid"
            class="stream-post"
            :imgref="(!showContext) ? post.author.picture : ((post.channel) ? post.channel.coverImage : post.author.picture)"
            :username="(!showContext) ? post.author.name : ((post.channel) ? post.channel.title : post.author.name)"
            colorClass="green"
            :title="post.title"
            :subtitle="post.subtitle"
            :titleurl="post.path"
            :author="post.author.name"
            :date="post.created"
            :content="post.content"
            :isOm="post.omOnly"
            @open="loadPost(post)"
            >
            <div slot="content-header" class="media-holder">
              <div v-if="post.media.length > 0" class="media-gallery responsive responsive-16by9">
                <div v-if="post.media[0].mimeType === 'text/html'" v-html="post.media[0].metadata.html" class="media-embed" :class="{ 'soundcloud-feature-wrapper': post.media[0].provider === 'soundcloud' }"></div>
                <img v-if="isImageMime(post.media[0].mimeType)" :src="post.media[0].source" />
              </div>
            </div>
          </ChannelPostSummary>
          </template>
        </template>
      </div>
      <div v-if="posts.length > 0 && streamType === 'mysangha-comments'" class="stream">
        <Comment
          :alt="post.author.name"
          v-for="post in posts"
          :key="post.nid"
          :imgref="post.author.picture"
          :author="post.author.name"
          :date="post.created"
          :isReply="false"
          :body="post.content"
          :isLoggedIn="false"
          :isMySangha="true"
          :commentPostUrl="post.postLink"
          :commentPostTitle="post.postTitle"
          :commentOriginalPostUrl="post.originalPostLink"
          >
        </Comment>
      </div>
      <div v-if="!loadingMorePosts && posts.length < 1" class="stream">
        <div v-if="noContentText" v-html="noContentText"></div>
      </div>

      <Loading v-if="loadingMorePosts" :loading="loadingMorePosts" :msg="defaultLoadingMsg" />

      <div v-if="showLoadMore" class="load-more" ref="loadMore">
        <a v-if="!loadingMorePosts" href="#load-more" @click="loadMorePosts">load more posts</a>
      </div>
    </div>
  </ResponsivePage>
</template>

<script>
import ResponsivePage from './ResponsivePage'
import FullPost from '../components/FullPost.vue'
import Comment from '@dharmachakra/tbco-shared-styles/src/components/Comment'
import Loading from '../components/Loading.vue'
import ChannelHero from '../components/ChannelHero.vue'
import ChannelMenu from '../components/ChannelMenu.vue'
import ChannelPostSummary from '@dharmachakra/tbco-shared-styles/src/components/ChannelPostSummary'
import channelApi from '../api/channels-api.js'
import { isImageMime } from '../api/media-api.js'
import { updateURL } from '../api/browser-api.js'
import FullscreenModal from '@dharmachakra/tbco-shared-styles/src/components/FullscreenModal.vue'
import UserData from '../UserData.js'
import { channelFollowText, shareSomethingLinks } from '../channel-helpers.js'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faThLarge } from '@fortawesome/free-solid-svg-icons'
import { faBars } from '@fortawesome/pro-light-svg-icons'
library.add(faThLarge, faBars)

const userData = new UserData()

const CONTENT_VIEW_LIST = 'list'
const CONTENT_VIEW_MAGAZINE = 'magazine'

export default {
  components: {
    ResponsivePage,
    Comment,
    ChannelHero,
    ChannelMenu,
    ChannelPostSummary,
    FullscreenModal,
    FullPost,
    Loading
  },
  props: {
    nid: [String, Number],
    title: String,
    type: String,
    content: String,
    coverImage: String,
    heroImage: String,
    path: String,
    stream: Array,
    defaultLoadingMsg: {
      type: String,
      default: 'loading posts...'
    },
    startingDisplay: String,
    startingChannelLink: {
      type: String,
      default: 'latest'
    },
    streamType: {
      type: String,
      default: 'posts'
    },
    noContentText: {
      type: String,
      default: ''
    },
    showChannelAdminMenu: {
      type: Boolean,
      default: false
    },
    isMember: {
      type: Boolean,
      default: false
    },
    useMoreLinks: {
      type: Boolean,
      default: false
    },
    showCommunityGuidelines: {
      type: Boolean,
      default: false
    },
    customChannelLinks: Array,
    moreChannelLinks: Array,
    total_posts: {
      type: Number
    },
    postsPerPage: {
      type: Number,
      default: 20
    },
    startingActiveFilter: {
      type: String,
      default: ''
    },
    startingSearchQuery: {
      type: String,
      default: ''
    },
    searchActive: {
      type: Boolean,
      default: false
    },
    nodeType: {
      type: String,
      default: 'posts'
    },
    channelFollowLink: String,
    showContext: {
      type: Boolean,
      default: false
    },
    showMenu: {
      type: Boolean,
      default: true
    },
    canPostContent: {
      type: Boolean,
      default: false
    },
    omOnly: {
      type: Boolean,
      default: false
    },
    userCanPost: {
      type: Boolean,
      default: true
    },
    terms: String
  },
  data() {
    return {
      contentView: CONTENT_VIEW_LIST,
      showLoadMore: true,
      loadingMorePosts: false,
      startingPost: this.postsPerPage,
      posts: this.stream,
      observer: null,
      activeChannelLink: this.startingChannelLink,
      display: this.startingDisplay,
      showChannelBellLink: false,
      postOpened: null,
      activeFilter: this.startingActiveFilter,
      searchQuery: this.startingSearchQuery,
      channelFilters: [
        {
          name: '',
          label: 'all'
        },
        {
          name: 'blogs',
          label: 'blogs'
        },
        {
          name: 'events',
          label: 'events'
        },
        {
          name: 'video',
          label: 'video'
        },
        {
          name: 'talks',
          label: 'talks'
        },
        {
          name: 'resources',
          label: 'files & resources'
        },
        {
          name: 'pictures',
          label: 'pictures'
        }
        // @todo - Order only content filter
        // {
        //   name: 'group_image_post',
        //   label: 'order-only'
        // }
      ]
    }
  },
  created() {
    if (this.activeChannelLink === 'groups') {
      this.contentView = CONTENT_VIEW_MAGAZINE
    }

    if (this.total_posts <= this.postsPerPage) {
      this.showLoadMore = false
      return
    }

    this.addLoadMoreObserver()
  },
  mounted() {
    let existingScript = document.querySelector('script[src="//e.issuu.com/embed.js"]')
    if (!existingScript) {
      let recaptchaScript = document.createElement('script')
      recaptchaScript.setAttribute('src', '//e.issuu.com/embed.js')
      document.head.appendChild(recaptchaScript)
    }
  },
  computed: {
    AboutPage() {
      return () => { return import('../components/AboutPage.vue') }
    },
    isAdmin() {
      return userData.isAdmin()
    },
    showChannelListLink() {
      return true
    },
    showChannelGridLink() {
      return true
    },
    shareSomethingLinksWrapper() {
      return shareSomethingLinks(true, this.nid)
    },
    channelFollowTextWrapper() {
      return channelFollowText(this.nodeType, this.isMember)
    },
    moreText() {
      if (this.nodeType.startsWith('group')) {
        return 'more from this group'
      } else {
        return 'more from this channel'
      }
    },
    channelLinks() {
      const defaultLinks = [
        {
          name: 'about',
          label: 'about'
        },
        {
          name: 'latest',
          label: 'latest'
        }
      ]

      return (this.customChannelLinks) ? this.customChannelLinks : defaultLinks
    }
  },
  methods: {
    addLoadMoreObserver() {
      this.observer = new IntersectionObserver(this.intersection, {
        threshold: 0.5
      })

      this.$nextTick(() => {
        this.observer.observe(this.$refs.loadMore)
      })
    },
    isImageMime(mimeType) {
      return isImageMime(mimeType)
    },
    loadPost(post) {
      this.postOpened = post
      this.updateURL()
    },
    changePage(name) {
      if (name === 'edit-channel') {
        window.location.href = '/node/' + this.nid + '/edit'
        return
      }

      if (name === 'manage-channel-members') {
        window.location.href = '/group/node/' + this.nid + '/admin/people'
        return
      }

      this.activeChannelLink = name
      this.updateURL()
    },
    updateURL() {
      let params = {}

      params.display = this.activeChannelLink

      if (this.postOpened) {
        params.openedPost = this.postOpened.nid
      }

      if (this.searchQuery) {
        params.q = this.searchQuery
      }

      if (this.activeFilter) {
        params.display = 'latest'
        params.f = this.activeFilter
      }

      updateURL(params)
    },
    intersection(entries, observer) {
      for (let entry of entries) {
        if (entry.isIntersecting) {
          // Uncomment below line if we want to remove the observer on the elememnt
          // seems fine here as the element is hidden as soon as there as no more posts to retrieve
          observer.unobserve(entry.target)
          this.loadMorePosts()
        }
      }
    },
    loadPosts() {
      this.showLoadMore = false
      this.loadingMorePosts = true
      this.startingPost = 0

      this.posts = []

      channelApi.fetchChannelPosts(this.nid, this.startingPost, this.postsPerPage, this.activeFilter, this.searchQuery, this.activeChannelLink, this.terms)
        .then((res) => {
          this.posts = res.channel.posts
          this.startingPost = this.postsPerPage
          if (res.channel.total_posts < this.startingPost) {
            this.showLoadMore = false
          } else {
            this.showLoadMore = true
            this.addLoadMoreObserver()
          }
        })
        .finally(() => {
          this.loadingMorePosts = false
        })
    },
    loadMorePosts() {
      this.loadingMorePosts = true

      channelApi.fetchChannelPosts(this.nid, this.startingPost, this.postsPerPage, this.activeFilter, this.searchQuery, this.activeChannelLink, this.terms)
        .then((res) => {
          this.posts = this.posts.concat(res.channel.posts)
          this.startingPost = this.startingPost + this.postsPerPage
          if (res.channel.total_posts < this.startingPost) {
            this.showLoadMore = false
          } else {
            this.showLoadMore = true
            this.addLoadMoreObserver()
          }
        })
        .finally(() => {
          this.loadingMorePosts = false
        })
    }
  }
}
</script>

<style lang="scss">
.stream, .channel-description {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 26px;
  letter-spacing: 0.77px;

  a {
    color: #000;
  }

  a:hover {
    text-decoration: underline;
  }

  a:active {
    color: #000;
  }

  a:visited {
    color: #000;
  }

  @media screen and (max-width: 768px) {
    padding: 0 1rem;
  }
}
@media screen  and (min-width: 1961px) {
  #mysangha-comments {
    .stream {
      .c-comment__author-bar {
        right: -42px;
      }
    }
  }
}
</style>

<style scoped lang="scss">
.stream-post {
  padding-bottom: 1rem;
  margin-bottom: 2em;
  border-bottom: 2px solid #999;

  &:last-child {
    border-bottom: none;
  }
}

.channel-description {
  margin-bottom: 3rem;
}

.stream, .channel-description, .load-more {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 26px;
  letter-spacing: 0.77px;

  a {
    color: #000;
  }

  a:hover {
    text-decoration: underline;
  }

  a:active {
    color: #000;
  }

  a:visited {
    color: #000;
  }
}

.channel-menu {
  padding: 1rem 0;
  border-bottom: 1px solid #979797;
  margin-bottom: 3em;
}

.load-more {
  margin: 4rem 0;
  text-align: center;
}

.btn-close-top, .btn-close-bottom {
  border: 0;
  background: none;
  font-size: 1em;
  font-weight: 600;
  &:hover {
    cursor: pointer;
  }
}
.btn-close-top {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
.btn-close-bottom {
  float: right;
  margin-bottom: 1rem;
}

.view-full-post {
  position: absolute;
  top: 1.2rem;
  right: 3rem;
  color: #000;
}

.channel-stream {
  min-height: 20rem;
}

.media-gallery {
  margin-bottom: 1em;
}

.add-content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-family: "Source Sans Pro", sans-serif;
  letter-spacing: 0.81pt;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 3rem;
  padding: 0 1rem;

  .group-link {
    img {
      display: inline-block;
      margin-right: 0.25rem;
    }

    a {
      display: inline-block;
      padding: 1rem;
    }
  }
}

.add-something {
  margin-right: 1rem;
}

.t-group {
  &.add-content {
    background-color: rgba(89, 128, 0, 0.85);
    color: #fff;

    a {
      color: #fff;

      &:hover {
        background-color: rgba(89, 128, 0, 0.65);
      }
    }
  }
}

.t-project {
  &.add-content {
    background-color: rgba(228, 97, 29, 0.85);
    color: #fff;

    a {
      color: #fff;

      &:hover {
        background-color: rgba(228, 97, 29, 0.65);
      }
    }
  }
}

.channel-summary {
  padding: 1rem 0;
  margin: 1rem 0;
  border-bottom: 1px solid #000;
}

.media-embed {
  position: absolute;
  height: 100%;
  width: 100% !important;

  ::v-deep {
    .iframe-feature-wrapper {
      width: 100% !important;
    }
  }
}

.media-holder {
  margin-top: 1rem;
  max-width: 720px;
  width: 100%;
}

.nomenu {
  margin-bottom: 3rem;
}

::v-deep {
  .modal-mask {
    background-color: unset;
  }

  .modal-container {
    position: relative;
    max-width: 1440px;
    width: 98vw;
    max-height: 98vh;
    height: 98vh;
    border-radius: 10px;
  }

  .iframe-feature-wrapper, .soundcloud-feature-wrapper {
     max-width: 720px;
  }

  .c-channel-post-summary__right-top {
    max-height: 615px;
  }

  .c-channel-post-summary__post-body-section .iframe-feature-wrapper,
  .c-channel-post-summary__post-body-section .soundcloud-feature-wrapper {
    padding-top: 34%;
    width: 50%;
    @media screen  and (width < 1100px) {
      width: 70%;
    }
    @media screen  and (width < 600px) {
      width: 90%;
    }
  }

  .c-post-body-section__content {
    @media screen and (max-width: 565px) {
      font-size: 16px;
    }
  }
}

.magazine {
  display: flex;
  flex-wrap: wrap;
  gap: 2.5rem;
  margin-bottom: 3rem;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    gap: 1.5rem;
  }

  @media screen and (max-width: 475px) {
    gap: 0.5rem;
    row-gap: 1.5rem;
  }

  &-title {
    padding: 1rem;
    margin: 0;
    display: block;
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  &-content {
    flex: 0 0 30%;
    max-width: 50%;
    background-color: #979797;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 768px) {
      flex: 0 0 46%;
      max-width: 50%;
    }

    @media screen and (max-width: 475px) {
      flex: 0 0 100%;
      max-width: 100%;
    }

    article {
      width: 100%;
      height: 100%;
    }

    h1 {
      font-size: 22px;
      margin: 0;
      padding: 0;

      @media screen and (max-width: 768px) {
        font-size: 20px;
      }

      @media screen and (max-width: 475px) {
        font-size: 18px;
      }
    }

    h2 {
      font-size: 20px;
      font-weight: 600;
      margin: 0;
      padding: 0;
      color: #fff;

      @media screen and (max-width: 768px) {
        font-size: 18px;
      }

      @media screen and (max-width: 475px) {
        font-size: 16px;
      }
    }

    ::v-deep {
      iframe {
        left: 0;
        top: 0;
      }
    }
  }
}

::v-deep {
  .c-channel-menu__right svg {
    cursor: pointer;
  }
}
</style>
