<template>
  <ResponsivePage>
    <div class="t-project">
      <HeroImage alt="Indian Order members, Sangharakshita and Buddha Rupa"
                :imgref="pageHeroImage.desktop"
                :imgSet="pageHeroImage.mobile + ' 548w, ' + pageHeroImage.tablet + ' 734w, ' + pageHeroImage.desktop + ' 2876w'"
                :imgSizes="'(max-width: 414px) 548px, (max-width: 768px) 734px, 2876px'"
      />
    </div>
    <SingleSlider
      :stories="isOM ? orderHighlights : thisWeek"
      :title="isOM ? 'welcome to the order online' : 'welcome to triratna'"
      :subtitle="isOM ? 'dharma practice spaces for all of us' : 'a new approach to buddhism – discover our community'"
      titleClass="order-blue"
      :titleHref="isOM ? 'order-connection' : 'triratna'"
    >
    </SingleSlider>
    <Slider
      class="tbco-live-slider section-top-border-grey shaded-section"
      :stories="tbcLiveStories"
      :itemsToShowDesktop="2.20"
      :itemsToShowTablet="1.33"
      :itemsToShowMobile="1.15"
      title="the buddhist centre [live]"
      subtitleHref="/live/"
      subtitle="meditations, classes, home retreats, events: buddhism for everyone"
      :colouredTitles="true"
      :showPagination="true"
      :showPlus="false"
      />
    <div class="t-tbco">
      <NewsletterSignup
        :hidden="!showNewsletterSignup"
        @hide="hideNewsletter"
        @show="showNewsletter"
        title="get the triratna highlights newsletter"
        subtitle="new events and the best of triratna in your mailbox, once a week"
        buttonColor="green"
      />
    </div>
    <div class="section-top-border-black shaded-section">
      <PodcastPlayer
        title="follow the podcast"
        link="https://audioboom.com/channels/4929068-the-buddhist-centre"
        linkText="meet triratna around the world: stories, voices, dharma life"
      />
    </div>

    <div v-if="isOM" class="news-section section-top-border-black shaded-section">
      <NewsPanel
        title="order connection"
        subtitle="a space to explore order practice"
        href="/order-connection"
        :news="orderConnectionStream"
      />
    </div>

    <div v-if="isOM" class="news-section">
      <NewsPanel
        title="community highlights"
        subtitle="discover the best of triratna online"
        href="/highlights/"
        :news="communityStream"
      />
    </div>

    <div v-if="isOM" class="t-tbco">
      <NewsletterSignup
        :hidden="!showOrderNewsletterSignup"
        @hide="hideOrderNewsletter"
        @show="showOrderNewsletter"
        list="order"
        title="subscribe to the order highlights newsletter"
        subtitle="the best of the order in your mailbox, once a month"
      />
    </div>

    <div v-if="!isOM" class="news-section">
      <NewsPanel
        title="community highlights"
        subtitle="discover the best of triratna online"
        href="/highlights/"
        :news="communityStream"
      />
    </div>

    <div class="fba-section section-top-border-black t-fba" :class="{ 'shaded-section' : true }">
      <Slider
        :stories="fbaLatest"
        :itemsToShowDesktop="6"
        :itemsToShowSmallScreen="4"
        :itemsToShowLargeMobile="2"
        :itemsToShowTablet="3"
        :itemsToShowMobile="2"
        :itemsToSlideDesktop="6"
        :itemsToSlideSmallScreen="4"
        :itemsToSlideLargeMobile="2"
        :itemsToSlideTablet="3"
        :itemsToSlideMobile="2"
        title="free buddhist audio"
        icon="/sites/all/themes/tbc/images/fba_logo_lg.png"
        subtitleHref="/fba"
        subtitle="the best dharma talks: 5,000 and counting"
        :colouredTitles="false"
        :squareMedia="true"
        :showPagination="true"
        :circularMedia="true"
        :talkPanels="true"
        :openLinksInNewTab="true"
        titleLineLimit=3
      >
      <template v-slot:placeholderBody>
        <div class="slide-image">
          <img src="https://www.freebuddhistaudio.com/images/default-square.jpg">
        </div>
      </template>
      </Slider>
    </div>

    <div v-if="isOM" class="news-section">
      <NewsPanel
      title="features"
      subtitle="original and inspiring dharma content"
      href="/features/"
      :news="featuresStream"
    />
    </div>

    <div v-if="!isOM" class="news-section" :class="{ 'shaded-section' : false }">
      <NewsPanel
      title="features"
      subtitle="original and inspiring dharma content"
      href="/features/"
      :news="featuresStream"
    />
    </div>

    <div v-if="isOM" class="shaded-section">
      <PodcastPlayer
        podcast="50voices"
        title="the 50 years, 50 voices project"
        link="/community/podcasts"
        linkText="subscribe to buddhist voices"
        secondaryLink="/stories/50voices/"
        secondaryLinkText="visit fifty years, fifty voices to hear highlights"
      />
    </div>

    <div class="news-section section-top-border-black shaded-section">
      <NewsPanel
        title="triratna news"
        subtitle="the latest from our community around the world"
        href="/news"
        :news="newsStream"
      />
    </div>

      <div class="t-project">
        <SocialBar title="the buddhist centre [social]"
                   subtitle="follow, subscribe, only connect"
                   :theme="isOM ? 'order' : ''"
        />
      </div>

    <div v-if="socialMediaContent.length" class="youtube-media">
      <Slider
        class="tbco-features-slider section-top-border-grey shaded-section"
        :stories="youtubeStream"
        :itemsToShowDesktop="2.44"
        title="watch & subscribe on youtube"
        subtitleHref="http://www.youtube.com/user/thebuddhistcentre"
        subtitle="catch up with all our live events & new video content"
        :colouredTitles="true"
        :showSlideTitles="false"
        iconName="youtube"
        iconClass="youtube-icon"
        />
    </div>
    <div v-if="instagramPosts.length" class="instagram-media">
    <Slider
        class="tbco-features-slider section-top-border-grey"
        :stories=instagramStream
        :itemsToShowDesktop="3.7"
        title="follow on instagram"
        subtitleHref="https://www.instagram.com/buddhistcenter/"
        subtitle="find a more fulfilling social"
        :colouredTitles="true"
        :showSlideTitles="false"
        :squareMedia="true"
        :instagramSlider="true"
        navPosition="side"
        iconName="instagram"
        iconClass="instagram-icon"
        />
    </div>
    <div class="t-project" slot="footer">
      <FooterLinks title="the best of triratna online since 2013"
        subtitle="the best of triratna online since 2013"
        link="link"
        :menus="footerMenus"
        :theme="isOM ? 'order' : ''"
      />
    </div>
  </ResponsivePage>
</template>

<script>
import axios from 'axios'
import ResponsivePage from './ResponsivePage'
import SingleSlider from '@dharmachakra/tbco-slider/src/components/SingleSlider'
import Slider from '@dharmachakra/tbco-slider/src/components/Slider'
import HeroImage from '@dharmachakra/tbco-shared-styles/src/components/HeroImage.vue'
import FooterLinks from '@dharmachakra/tbco-shared-styles/src/components/FooterLinks.vue'
import NewsPanel from '@dharmachakra/tbco-shared-styles/src/components/NewsPanel.vue'
import pageHeroImage from '../images/tbco-home-hero.jpg'
import PodcastPlayer from '../components/organisms/PodcastPlayer'
import { NewsletterSignup } from '@dharmachakra/newsletter-signup-component'
import SocialBar from '@dharmachakra/tbco-shared-styles/src/components/SocialBar.vue'
import { fetchPageData } from '../api/browser-api.js'
import '@dharmachakra/newsletter-signup-component/dist/newsletter-signup-component.css'
import '@dharmachakra/tbco-panels/dist/tbco-panels.css'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faSquareFacebook, faInstagram, faYoutube, faSoundcloud } from '@fortawesome/free-brands-svg-icons'
library.add(faSquareFacebook, faInstagram, faYoutube, faSoundcloud)

export default {
  components: {
    ResponsivePage,
    SingleSlider,
    Slider,
    HeroImage,
    PodcastPlayer,
    NewsletterSignup,
    SocialBar,
    NewsPanel,
    FooterLinks
  },
  data () {
    return {
      foot1Menu: {},
      foot2Menu: {},
      foot3Menu: {},
      foot4Menu: {},
      foot5Menu: {},
      foot6Menu: {},
      canEdit: false,
      pageHeroImage,
      showNewsletterSignup: true,
      showOrderNewsletterSignup: true,
      footerMenus: [],
      socialMediaContent: [],
      instagramPosts: []
    }
  },
  created () {
    if (document.__TBCO__.app && document.__TBCO__.app.menus) {
      this.foot1Menu = document.__TBCO__.app.menus.foot1 || {}
      this.foot2Menu = document.__TBCO__.app.menus.foot2 || {}
      this.foot3Menu = document.__TBCO__.app.menus.foot3 || {}
      this.foot4Menu = document.__TBCO__.app.menus.foot4 || {}
      this.foot5Menu = document.__TBCO__.app.menus.foot5 || {}
      this.foot6Menu = document.__TBCO__.app.menus.foot6 || {}

      this.footerMenus = [
        this.formatFooterMenu(this.foot1Menu),
        this.formatFooterMenu(this.foot2Menu),
        this.formatFooterMenu(this.foot3Menu),
        this.formatFooterMenu(this.foot4Menu),
        this.formatFooterMenu(this.foot5Menu),
        this.formatFooterMenu(this.foot6Menu)
      ]

      this.socialMediaContent = document.__TBCO__.socialMediaContent || []
      this.instagramPosts = document.__TBCO__.instagramPosts || []
    }

    const pageData = fetchPageData()
    this.pageHeroImage = pageData.heroImage

    axios.get('/api/v1/roles/hide%20newsletter%20signup')
      .then(response => {
        if (response.data.has_role) this.showNewsletterSignup = false
      }).catch(() => {
        // @todo - log error?
      })
  },
  computed: {
    isOM() {
      return !!document.__TBCO__.isOrderMember
    },
    isLoggedIn() {
      return (document.__TBCO__.userView)
    },
    thisWeek () {
      const section = document.__TBCO__.frontpageStoriesSections
        .filter(section => section.label === 'this week')
      const stories = (section.length > 0) ? section[0].stories : []
      if (!stories) return []
      return stories
        .map(story => Object.assign({ is_iframe: story.body.includes('iframe-feature-wrapper') }, story))
    },
    orderHighlights () {
      const section = document.__TBCO__.frontpageStoriesSections
        .filter(section => section.label === 'order highlights')
      const stories = (section.length > 0) ? section[0].stories : []
      if (!stories) return []
      return stories
        .map(story => Object.assign({ is_iframe: story.body.includes('iframe-feature-wrapper') }, story))
    },
    tbcLiveStories () {
      const section = document.__TBCO__.frontpageStoriesSections
        .filter(section => section.label === 'tbco live')
      const stories = (section.length > 0) ? section[0].stories : []
      if (!stories) return []
      return stories
        .map(story => Object.assign({ is_iframe: story.body.includes('iframe-feature-wrapper') }, story))
    },
    fbaLatest() {
      const stream = document.__TBCO__.fbaLatest
      const fbaBaseUrl = document.__TBCO__.fbaBaseUrl
      if (!stream) return []
      return stream
        .map(story => {
          let image = (story.speaker_image) ? fbaBaseUrl + story.speaker_image : fbaBaseUrl + '/images/default-square.jpg'
          if (story.image) {
            image = fbaBaseUrl + story.image
          }

          return Object.assign(story, {
            is_iframe: false,
            subtitle: story.speaker,
            link: fbaBaseUrl + '/audio/details?num=' + story.cat_num,
            image: image,
            om_only: story.om === '1'
          })
        })
    },
    featuresStream () {
      const stream = document.__TBCO__.featuresStream
      if (!stream) return []
      return stream
        .map(story => Object.assign({ is_iframe: story.body.includes('iframe-feature-wrapper') }, story))
    },
    youtubeStream () {
      return this.socialMediaContent
        .map(story => {
          return {
            thumbnail: (story.thumbnails && story.thumbnails.standard) ? '<img src="' + story.thumbnails.standard.url + '" title="' + story.title + ' image" />' : null,
            body: '<iframe loading="lazy" src="https://www.youtube.com/embed/' + story.resourceId.videoId + '?modestbranding=1&amp;showinfo=0&amp;fs=0&amp;autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
            title: story.title,
            is_iframe: true
          }
        })
    },
    instagramStream() {
      return this.instagramPosts.map(story => {
        let body
        let thumbnail
        let isIframe
        if (story.media_type === 'IMAGE' || story.media_type === 'CAROUSEL_ALBUM') {
          body = '<a href="' + story.permalink + '"> <img src="' + story.media_url + '" title="' + story.caption + ' image" /> </a>'
          thumbnail = null
          isIframe = false
        } else if (story.media_type === 'VIDEO') {
          body = ''
          thumbnail = '<a href="' + story.permalink + '"> <img src="' + story.thumbnail_url + '" title="' + story.caption + ' image" /> </a>'
          isIframe = true
        }

        return {
          body: body,
          title: story.caption,
          link: story.permalink,
          thumbnail: thumbnail,
          is_iframe: isIframe
        }
      })
    },
    communityStream () {
      const stream = document.__TBCO__.communityStream
      if (!stream) return []
      return stream
        .map(story => Object.assign({ is_iframe: story.body.includes('iframe-feature-wrapper') }, story))
    },
    newsStream () {
      const stream = document.__TBCO__.newsStream
      if (!stream) return []
      return stream
        .map(story => Object.assign({ is_iframe: story.body.includes('iframe-feature-wrapper') }, story))
    },
    orderConnectionStream() {
      const stream = document.__TBCO__.orderConnectionStream
      if (!stream) return []
      return stream
        .map(story => Object.assign({ is_iframe: story.body.includes('iframe-feature-wrapper') }, story))
    }
  },
  methods: {
    formatFooterMenu(menu) {
      menu.title = menu.name

      if (!menu.menu_items) {
        return menu
      }

      menu.links = menu.menu_items.map((item) => {
        item.label = item.name
        item.href = item.url
        return item
      })

      return menu
    },
    hideNewsletter () {
      axios.put('/api/v1/roles/hide%20newsletter%20signup')
        .then(response => {
          if (response.data.ok) this.showNewsletterSignup = false
        })
    },
    showNewsletter () {
      axios.delete('/api/v1/roles/hide%20newsletter%20signup')
        .then(response => {
          if (response.data.ok) this.showNewsletterSignup = true
        })
    },
    hideOrderNewsletter () {
      this.showOrderNewsletterSignup = false
      axios.put('/api/v1/roles/hide%20order%20newsletter%20signup')
        .then(response => {
          if (response.data.ok) this.showNewsletterSignup = false
        })
    },
    showOrderNewsletter () {
      this.showOrderNewsletterSignup = true
      axios.delete('/api/v1/roles/hide%20order%20newsletter%20signup')
        .then(response => {
          if (response.data.ok) this.showNewsletterSignup = true
        })
    }
  }
}
</script>

<style lang="scss">
.stream .audioboo-feature-wrapper iframe {
  max-width: 720px;
  max-height: 405px;
}

.t-project {
  .c-channel-hero__image-container {
    margin-top: 1.75rem;
  }
}

.hooper-slide-inner {
  display: flex;
  flex-direction: column;
}

.hooper-slide-inner, .single-slide-media {
  height: 100%;
}

.hooper-slide {
  height: unset;
}

.iframe-feature-wrapper, .soundcloud-feature-wrapper {
  height: 100%;

  iframe {
    height: 100% !important;
  }
}

// Overrides
.hooper-slider-section.single-slider .hooper-slide-inner .single-slide-info {
    overflow: unset;
}

@media screen and (max-width: 810px) {
  .t-project .c-social-flex-container {
    margin-left: unset !important;
    margin-right: unset !important;
  }
}

.hooper-slider-section.single-slider {
  overflow: hidden;
  padding-bottom: 5rem;

  @media screen and (max-width: 767px) {
    padding-bottom: 3rem;
  }
}

.single-slide-media {
  > div {
    height: 100%;
  }
}

.hooper-slider-title-text {
  width: 100%;
}

.hooper-slider-section .hooper-slider-section-title h2 {
  @media screen and (max-width: 980px) {
    display: block;
  }
}

.news-section .news-title {
  @media screen and (max-width: 564px) {
    font-size: 18px;
    letter-spacing: 0.56px;
    line-height: 20px;
    color: white;
    font-weight: 700;
    margin-top: 0em;
    margin-bottom: 0em;
  }
}

.signup-form .button-green {
  background-color: #468149;
  border: 1px solid #468149;
}

.news-section .news-subtitle {
  font-weight: 600;
}

.community-section {
  padding: 0 20px 52px 20px;
}

.news-section {
  padding: 0 20px 52px 20px;
  @media screen and (max-width: 980px) {
    padding: 0 0 52px 0;
  }
}
</style>

<style scoped lang="scss">
::v-deep {
  .instagram-media {
    .embed-slide, .embed-slide iframe {
      width: 280px;
      height: 280px;
    }
  }
  .youtube-media {
    .hooper-slider-section {
      padding-bottom: 33px;
    }
  }

  .single-slide-media-body iframe {
    height: 100%;
    width: 100%;
  }
}
</style>
