<template>
  <div>
    <div v-if="!activateSearch" class="c-channel-menu">
      <div class="c-channel-menu__left">

        <template v-if="useMoreLinks">
          <div v-for="link in links.slice(0, 2)" :key="link.name" class="c-channel-menu__link" :class="{ active: activeLink === link.name }"><a @click="$emit('selected', link.name)" :href="link.href" :target="link.target">{{ link.label }} <font-awesome-icon v-if="link.sublinks && link.sublinks.length > 0" class="icon icon-sortDown" :icon="['fas', 'sort-down']"/></a></div>
          <a v-if="links.length > 2" class="c-channel-menu__link more"><span class="long">{{ moreText }}</span><span class="short">more</span> <font-awesome-icon class="icon icon-sortDown" :icon="['fas', 'sort-down']"/>
          <ul class="c-channel-menu__more-links">
            <li v-for="sublink in links.slice(2)" :key="sublink.name" :class="{ active: activeLink === sublink.name }">
              <a @click="$emit('selected', sublink.name)" :href="sublink.href" :target="sublink.href.startsWith('http') ? '_blank' : '_self'">{{ sublink.label }} <font-awesome-icon v-if="sublink.sublinks && sublink.sublinks.length > 0" class="icon icon-sortDown" :icon="['fas', 'sort-down']"/></a>
            </li>
          </ul>
          </a>
        </template>

        <template v-else>
          <div v-for="link in links" :key="link.name" class="c-channel-menu__link" :class="{ active: activeLink === link.name }">
            <a @click="$emit('selected', link.name)" :href="link.href" :target="link.target">{{ link.label }} <font-awesome-icon v-if="link.sublinks && link.sublinks.length > 0" class="icon icon-sortDown" :icon="['fas', 'sort-down']"/>
            <ul v-if="links.sublinks && link.sublinks.length > 0" class="c-channel-menu__more-links">
              <li v-for="sublink in links.sublinks" :key="sublink.name" :class="{ active: activeLink === sublink.name }">
                <a @click="$emit('selected', sublink.name)" :href="sublink.href" :target="sublink.href.startsWith('http') ? '_blank' : '_self'">{{ sublink.label }} <font-awesome-icon v-if="sublink.sublinks && sublink.sublinks.length > 0" class="icon icon-sortDown" :icon="['fas', 'sort-down']"/></a>
              </li>
            </ul>
            </a>
          </div>

          <a v-if="moreLinks.length" class="c-channel-menu__link more">
            <span class="long">{{ moreText }}</span>
            <span class="short">more</span>
            <font-awesome-icon class="icon icon-sortDown" :icon="['fas', 'sort-down']"/>
            <ul class="c-channel-menu__more-links">
              <li v-for="sublink in moreLinks" :key="sublink.name" :class="{ active: activeLink === sublink.name }">
                <a @click="handleClick(sublink)" :href="sublink.href" :target="sublink.href.startsWith('http') ? '_blank' : '_self'">
                  {{ sublink.label }}
                  <font-awesome-icon v-if="sublink.sublinks && sublink.sublinks.length > 0" class="icon icon-sortDown" :icon="['fas', 'sort-down']"/>
                </a>
              </li>
            </ul>
          </a>
        </template>
      </div>

      <div class="c-channel-menu-icons">
        <div v-if="showSearchIcon" class="c-channel-menu-search">
          <font-awesome-icon class="icon icon-searchSmall" :icon="['fal', 'search']" @click="$emit('activateSearch'); activateSearch = true" />
        </div>
        <div v-if="showAdminMenu" class="c-channel-menu-cog">
          <a>
          <font-awesome-icon class="icon icon-searchSmall" :icon="['fa', 'cog']" />
          <ul class="c-channel-menu__more-links">
            <li :class="{ active: activeLink === 'edit-channel' }">
              <a @click="$emit('selected', 'edit-channel')">edit channel</a>
            </li>
            <li :class="{ active: activeLink === 'manage-channel-members' }">
              <a @click="$emit('selected', 'manage-channel-members')">manage channel members</a>
            </li>
          </ul>
          </a>
        </div>
      </div>

      <div class="c-channel-menu__right">
        <font-awesome-icon v-if="showListLink" @click="$emit('activateListView')" class="icon icon-channelMenuIcons" :icon="['fat', 'bars']"/>
        <font-awesome-icon v-if="showGridLink" @click="$emit('activateGridView')" class="icon icon-channelMenuIcons" :icon="['fat', 'grid-2']"/>
        <font-awesome-icon v-if="showBellLink" @click="$emit('activateNotifications')" class="icon icon-channelMenuIcons" :icon="['fal', 'bell-plus']"/>
      </div>
    </div>

    <div v-if="activateSearch" class="c-channel-search">
      <form class="c-channel-search__form" @submit.prevent="search">
        <div class="c-channel-search__filters">
          <div>
            <span class="c-channel-menu__label">
              filter posts by:
            </span>
            <div v-for="link in filters" :key="link.name" class="c-channel-menu__link" :class="{ active: formActiveFilter === link.name }">
              <a @click="formActiveFilter = link.name; search()" :href="link.href" :target="link.target">{{ link.label }}</a>
            </div>
          </div>
          <div>
            <button @click="clear" type="button" class="c-channel-search__filters-clear">clear filters/search</button> <button @click="close" type="button" class="c-channel-search__filters-close">x</button>
          </div>
        </div>
        <div class="c-channel-search__query">
          <button class="c-channel-search__query-button"><font-awesome-icon class="icon icon-searchSmall" :icon="['fal', 'search']" />
          </button><input v-model="formQuery" type="text" placeholder="search this channel/group for anything (or choose a filter above)" class="c-channel-search__query-term" />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String
    },
    subtitle: {
      type: String
    },
    link: {
      type: String
    },
    menus: {
      type: Array
    },
    activeLink: {
      type: String,
      default: ''
    },
    links: {
      type: Array,
      default() {
        return []
      }
    },
    moreLinks: {
      type: Array,
      default() {
        return []
      }
    },
    activeFilter: {
      type: String,
      default: ''
    },
    filters: {
      type: Array,
      default() {
        return []
      }
    },
    showAdminMenu: {
      type: Boolean,
      default: false
    },
    showListLink: {
      type: Boolean,
      default: false
    },
    showGridLink: {
      type: Boolean,
      default: false
    },
    showBellLink: {
      type: Boolean,
      default: false
    },
    useMoreLinks: {
      type: Boolean,
      default: true
    },
    showSearchIcon: {
      type: Boolean,
      default: true
    },
    searchActive: {
      type: Boolean,
      default: false
    },
    query: {
      type: String,
      default: ''
    },
    moreText: {
      type: String,
      default: 'more from this channel'
    }
  },
  data() {
    return {
      activateSearch: this.searchActive,
      formActiveFilter: this.activeFilter,
      formQuery: this.query
    }
  },
  methods: {
    close() {
      this.activateSearch = false
      this.$emit('deactivateSearch')
    },
    clear() {
      this.formActiveFilter = this.activeFilter
      this.formQuery = ''
      this.search()
    },
    search() {
      this.$emit('search', {
        query: this.formQuery,
        filter: this.formActiveFilter
      })
    },
    handleClick(sublink) {
      if (!sublink.href.startsWith('http') &&
          !sublink.href.startsWith(('mailto'))) {
        this.$emit('selected', sublink.name)
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@dharmachakra/tbco-shared-styles/src/assets/scss/partials/_variables.scss';

.c-channel-menu {
  display: flex;
  align-items: center;
  font-family: $font-family-open-sans;
  color: black;
  font-size: 20px;
  letter-spacing: 0.63px;
  font-weight: 600;
  font-style: normal;

  @media screen and (max-width: $tablet-max-size) {
    font-size: 18px;
    letter-spacing: 0.56px;
  }

  @media screen and (max-width: 652px) {
    font-size: 16px;
    letter-spacing: 0.5px;
  }

  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
  }

  .icon {
    border-radius: unset;
  }
}

.c-channel-menu-icons {
  display: flex;
  gap: 0.75rem;
  position: relative;
  top: 3px;
}

.c-channel-menu__label {
  margin-right: 14px;
}

.c-channel-menu__link.more {
  color: $colour-orange-burnt;
}

.c-channel-menu__more-links {
  display: none;
  position: absolute;
  top: 1.8rem;
  right: 0;
  left: 0;
  background-color: #fff;
  text-align: left;
  z-index: 999;
  border: 1px solid #757575;
  color: #757575 !important;

  li {
    display: block;

    &:first-child a {
      border-top: none;
    }
  }

  li a {
    padding: 0.2rem 0.5rem;
    display: inline-block;
    cursor: pointer;
    text-decoration: none;
    width: 100%;

    border-top: 1px solid #757575;
    color: #757575;
    text-decoration: none;

    font-family: "Source Sans Pro", sans-serif;
    font-weight: 400;
    letter-spacing: 0.63px;

    &:hover {
      color: #fff;
      background-color: #e4611d;
    }
  }

  @media screen and (max-width: 652px) {
    left: unset;
    width: 10rem;
    top: 1.5rem;
  }
}

.c-channel-menu-cog {
  position: relative;

  .c-channel-menu__more-links {
    right: unset;
    left: 0;
    width: 14rem;

    a {
      cursor: pointer;
    }
  }
}

.c-channel-menu__link:hover .c-channel-menu__more-links,.c-channel-menu-cog:hover .c-channel-menu__more-links {
  display: block;
}

.c-channel-menu {
    gap: 1rem;
    width: 100%;

    @media screen and (min-width: $desktop-min-size) {
      display: flex;
      flex-wrap: nowrap;
    }
}

.c-channel-menu__link {
  position: relative;
  cursor: pointer;
  border-right: 1px solid rgba(117,117,117,1);
  padding: 0 0.5rem;
  margin: 0;
  text-align: center;
  text-decoration: underline;
  text-transform: lowercase;

  &:last-child {
    border-right: none;
  }

    display: inline-block;

    a {
      color: black;
    }
    .short{
        display: none;
    }
    .long{
        display: inline;
    }
    @media screen and (max-width: 652px) {
        .short{
            display: inline;
        }
        .long{
            display: none;
        }
    }
}

.active {
    color: #757575;
    font-family: $font-family-open-sans;
    font-weight: 600;
    font-style: normal;
    text-decoration: none;
    display: inline-block;

    a {
      color: #757575;
      text-decoration: none;
    }
}

.mobile-toggle{
    justify-content: left;
    text-align: left;
    flex-grow: 1;
}

.c-channel-menu__divider {
    color: rgba(117,117,117,1);
    font-family: $font-family-open-sans;
    font-size: 20px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0.59px;
    text-align: left;
    display: inline-block;
    flex-grow: 1;
}

.c-channel-menu__left {
    display: flex;
    align-items: center;

    @media screen and (max-width: 768px) {
        margin-left: 0px;
        width: 100%;
    }
}

.c-channel-menu__right {
    display: flex;
    flex-basis: auto;
    width: 100px;
    align-items: center;
    margin-left: auto;

    @media screen and (max-width: $mobile-max-size){
        margin-right: -9px;
    }
}

.c-channel-search {
  font-family: $font-family-open-sans;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.59px;
  color: $colour-grey-m;

  @media screen and (max-width: $tablet-max-size) {
    font-size: 18px;
    letter-spacing: 0.56px;
  }

  @media screen and (max-width: 652px) {
    font-size: 16px;
    letter-spacing: 0.5px;
  }
}

.c-channel-search__filters {
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    background-color: unset;
    border: 0;
    color: $colour-orange-burnt;
    text-decoration: none;
    cursor: pointer;
    &.c-channel-search__filters-clear {
      text-decoration: underline;
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: start;
  }
}

.c-channel-search__query {
  display: flex;
}

.c-channel-search__query-button {
  box-sizing: border-box;
  width: 2rem;
  height: 3rem;
  padding: 0.5rem;
  border: 0;
  outline: 0;
  background-color: #fff;
  cursor: pointer;
  color: $colour-grey-m;
  .icon {
    color: $colour-grey-m;
  }
}

.c-channel-search__query-term {
  box-sizing: border-box;
  display: inline-block;
  width: calc(100% - 2rem);
  height: 3rem;
  padding: 0.5rem;
  border: 0;
  outline: 0;
}

.c-channel-search__filters-close {
  margin-left: 1rem;
  padding: 0.5rem;
}

.t-group {
  .icon-searchSmall, .icon-sortDown {
    color: $colour-group-green;
  }
  .c-channel-search__filters {
    button {
      color: $colour-group-green;
    }
  }
  .c-channel-menu__link.more {
    color: $colour-group-green;
  }
  .c-channel-menu__more-links a:hover {
    background-color: $colour-group-green;
  }
}
</style>
