<template>
  <ResponsivePage>
    <div :class="(!channel || channel.type === 'channel') ? 't-project' : 't-group'">
      <ChannelHero v-if="channel" :title="channel.title"
                :alt="channel.title"
                :subtitle="channelFollowText"
                :imgref="channel.heroImage"
                :subtitleURL="channelFollowLink"
                :shareSomethingLinks="shareSomethingLinks"
                :showShareLink="canPostContent"
                :isOm="channel.omOnly"
                :userCanPost="userCanPost"
      />
      <HeroImage v-if="nodeType === 'keystone'" :alt="title"
        :imgref="pageHeroImage.desktop"
        :imgSet="pageHeroImage.mobile + ' 548w, ' + pageHeroImage.tablet + ' 734w, ' + pageHeroImage.desktop + ' 2876w'"
        :imgSizes="'(max-width: 414px) 548px, (max-width: 768px) 734px, 2876px'"
      />
    </div>

    <div class="channel-menu" :class="(!channel || channel.type === 'channel') ? 't-project' : 't-group'">
      <ChannelMenu
        v-if="channel"
        :links="channelLinks"
        :moreLinks="moreChannelLinks"
        :filters="channelFilters"
        :activeFilter="activeFilter"
        :activeLink="activeChannelLink"
        :showAdminMenu="showChannelAdminMenu"
        :showSearchIcon="isAdmin"
        :showListLink="showChannelListLink"
        :showGridLink="showChannelGridLink"
        :showBellLink="showChannelBellLink"
        :useMoreLinks="useMoreLinks"
        :query="searchQuery"
        :searchActive="searchActive"
        @selected="changePage"
        @search="data => { this.activeFilter = data.filter; this.searchQuery = data.query; updateURL(); loadPosts(); }"
      />
    </div>

    <FullPost v-bind="$props" />
  </ResponsivePage>
</template>

<script>
import Vue from 'vue'
import ResponsivePage from './ResponsivePage'
import FullPost from '../components/FullPost'
import ChannelHero from '../components/ChannelHero.vue'
import ChannelMenu from '../components/ChannelMenu.vue'
import HeroImage from '@dharmachakra/tbco-shared-styles/src/components/HeroImage'
import { fetchPageData, updateURL } from '../api/browser-api.js'
import UserData from '../UserData.js'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faBars as farBars, faSearch, faArrowAltSquareUp } from '@fortawesome/pro-regular-svg-icons'
import { faSortDown } from '@fortawesome/pro-solid-svg-icons'
import { faBellPlus } from '@fortawesome/pro-light-svg-icons'
import { faGrid2, faBars as fatBars, faArrowUpFromSquare } from '@fortawesome/pro-thin-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(farBars, fatBars, faSearch, faSortDown, faBellPlus, faGrid2, faArrowAltSquareUp, faArrowUpFromSquare)
Vue.component('font-awesome-icon', FontAwesomeIcon)

const userData = new UserData()

/**
 * @todo - Facebook video required the sdk, which requires jQuery, so leaving for now.
 * sites/all/modules/contrib/video_embed_facebook_tbc/js/video_embed_facebook_sdk.js
 */
export default {
  components: {
    ResponsivePage,
    FullPost,
    ChannelHero,
    ChannelMenu,
    HeroImage
  },
  props: {
    nid: [String, Number],
    author: Object,
    created: String,
    title: String,
    subtitle: String,
    type: String,
    content: String,
    heroImage: String,
    media: {
      type: Array,
      default: () => {
        return []
      }
    },
    resources: {
      type: Array,
      default: () => {
        return []
      }
    },
    tags: {
      type: Array,
      default: () => {
        return []
      }
    },
    quotes: {
      type: Array,
      default: () => {
        return []
      }
    },
    channel: Object,
    showChannelAdminMenu: {
      type: Boolean,
      default: false
    },
    isMember: {
      type: Boolean,
      default: false
    },
    loadISSUU: {
      type: Boolean,
      default: false
    },
    showLabel: {
      type: Boolean,
      default: false
    },
    nodeType: {
      type: String,
      default: 'posts'
    },
    commentsHTML: {
      type: String,
      default: ''
    },
    commentCount: {
      type: Number,
      default: 0
    },
    canPostContent: {
      type: Boolean,
      default: false
    },
    omOnly: {
      type: Boolean,
      default: false
    },
    userCanPost: {
      type: Boolean,
      default: true
    },
    userCanComment: {
      type: Boolean,
      default: true
    },
    commentLevel: {
      type: Number,
      default: 0
    },
    startingActiveFilter: {
      type: String,
      default: ''
    },
    startingSearchQuery: {
      type: String,
      default: ''
    },
    searchActive: {
      type: Boolean,
      default: false
    },
    useMoreLinks: {
      type: Boolean,
      default: false
    },
    customChannelLinks: Array,
    moreChannelLinks: Array
  },
  data() {
    return {
      activeChannelLink: this.startingChannelLink,
      showChannelListLink: false,
      showChannelGridLink: false,
      showChannelBellLink: false,
      pageHeroImage: (this.channel) ? this.channel.heroImage : null,
      activeFilter: this.startingActiveFilter,
      searchQuery: this.startingSearchQuery,
      channelFilters: [
        {
          name: '',
          label: 'all'
        },
        {
          name: 'blogs',
          label: 'blogs'
        },
        {
          name: 'events',
          label: 'events'
        },
        {
          name: 'video',
          label: 'video'
        },
        {
          name: 'talks',
          label: 'talks'
        },
        {
          name: 'resources',
          label: 'files & resources'
        },
        {
          name: 'pictures',
          label: 'pictures'
        }
        // @todo - Order only content filter
        // {
        //   name: 'group_image_post',
        //   label: 'order-only'
        // }
      ]
    }
  },
  created() {
    if (!this.pageHeroImage) {
      const pageData = fetchPageData()
      this.pageHeroImage = pageData.heroImage
    }
  },
  computed: {
    isAdmin() {
      return userData.isAdmin()
    },
    shareSomethingLinks() {
      if (!this.canPostContent) {
        return []
      }

      if (!this.channel) {
        return []
      }

      return [
        {
          href: '/node/add/group-text-post/' + this.channel.nid,
          label: 'write'
        },
        {
          href: '/node/add/group-resources/' + this.channel.nid,
          label: 'resources'
        },
        {
          href: '/node/add/group-talk/' + this.channel.nid,
          label: 'audio'
        },
        {
          href: '/node/add/group-video-embed/' + this.channel.nid,
          label: 'video'
        },
        {
          href: '/node/add/group-image-post/' + this.channel.nid,
          label: 'pictures'
        }
      ]
    },
    channelFollowText() {
      if (!this.channel) {
        return ''
      }

      if (this.channel.type.startsWith('group')) {
        return (this.isMember) ? 'leave this group' : 'join this group'
      } else {
        return (this.isMember) ? 'unfollow this channel' : 'follow this channel'
      }
    },
    channelFollowLink() {
      if (!this.channel) {
        return ''
      }

      return (this.isMember) ? '/group/node/' + this.channel.nid + '/unsubscribe' : '/group/node/' + this.channel.nid + '/subscribe/og_group_ref'
    },
    channelLinks() {
      const defaultLinks = [
        {
          name: 'about',
          label: 'about'
        },
        {
          name: 'latest',
          label: 'latest'
        }
      ]

      return (this.customChannelLinks) ? this.customChannelLinks : defaultLinks
    },
    themeLinks() {
      return this.tags.map((tag) => {
        return {
          label: tag.name.toLowerCase(),
          href: '/tags/' + tag.name.toLowerCase()
        }
      })
    }
  },
  methods: {
    loadPosts() {
      // @todo - redirect to channel page with correct filters.
    },
    updateURL() {
      let params = {}

      params.display = this.activeChannelLink

      if (this.postOpened) {
        params.openedPost = this.postOpened.nid
      }

      if (this.searchQuery) {
        params.q = this.searchQuery
      }

      if (this.activeFilter) {
        params.f = this.activeFilter
      }

      updateURL(params)
    },
    changePage(name) {
      // @todo - update ChannelMenu componet to pass href so we don't need to preform the
      // below window location change
      this.activeChannelLink = name

      if (name === 'edit-channel') {
        window.location.href = '/node/' + this.nid + '/edit'
        return
      }

      if (name === 'manage-channel-members') {
        window.location.href = '/group/node/' + this.nid + '/admin/people'
        return
      }

      if (name === 'about') {
        window.location.href = this.channel.path
        return
      }

      if (name === 'latest') {
        window.location.href = this.channel.path + '?display=latest'
      }
    },
    isImageMime(mimeType) {
      const imageMimeTypes = [
        'image/png',
        'image/jpg',
        'image/jpeg',
        'image/gif'
      ]

      return imageMimeTypes.indexOf(mimeType) !== -1
    }
  }
}
</script>

<style scoped lang="scss">
.channel-menu {
  padding: 1rem 0;
  border-bottom: 1px solid #979797;
  margin-bottom: 2rem;
}
</style>
