<template>
  <ResponsivePage>
    <div class="t-project">
      <HeroImage :alt="title"
        :imgref="pageHeroImage.desktop"
        :imgSet="pageHeroImage.mobile + ' 548w, ' + pageHeroImage.tablet + ' 734w, ' + pageHeroImage.desktop + ' 2876w'"
        :imgSizes="'(max-width: 414px) 548px, (max-width: 768px) 734px, 2876px'"
      />
    </div>

    <div class="page">
      <div class="page-main">
        <div class="order-address-html" v-html="htmlContent"></div>
      </div>
    </div>
  </ResponsivePage>
</template>

<script>
import ResponsivePage from './ResponsivePage'
import HeroImage from '@dharmachakra/tbco-shared-styles/src/components/HeroImage'
import { fetchPageData } from '../api/browser-api.js'

export default {
  components: {
    ResponsivePage,
    HeroImage
  },
  props: {
    id: [String, Number],
    title: String,
    content: String,
    heroImage: String,
    marqueeImage: Object,
    htmlContent: String
  },
  data() {
    return {
      pageHeroImage: {}
    }
  },
  created() {
    if (this.marqueeImage) {
      this.pageHeroImage = {
        desktop: this.marqueeImage.path,
        tablet: this.marqueeImage.path,
        mobile: this.marqueeImage.path
      }
    } else {
      const pageData = fetchPageData()
      this.pageHeroImage = pageData.heroImage
    }
  }
}
</script>

<style scoped lang="scss">
.order-address-html ::v-deep .report-image__order-address {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.page-main {
  margin: 0 auto;
  width: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 0 1.2rem;
}

::v-deep .page {
  font-family: 'Source Sans Pro', sans-serif;

  p, ul, ol {
    font-size: 20px;
    letter-spaceing: 0.59px;
  }

  a {
    color: #000;
  }
}

h1, h2 {
  margin: 30px 0;
  padding: 0;
}
</style>
