<template>
  <ResponsivePage>
    <div class="t-project">
      <ChannelHero
          v-if="heroTitle"
          :title="heroTitle"
          :alt="title"
          :imgref="pageHeroImage.desktop"
          />

      <HeroImage
        v-else
        :alt="title"
        :imgref="pageHeroImage.desktop"
        :imgSet="pageHeroImage.mobile + ' 548w, ' + pageHeroImage.tablet + ' 734w, ' + pageHeroImage.desktop + ' 2876w'"
        :imgSizes="'(max-width: 414px) 548px, (max-width: 768px) 734px, 2876px'"
        />
    </div>

    <div v-if="menu" class="channel-menu t-project">
      <ChannelMenu
        :links="menuItems"
        :useMoreLinks="useMoreLinks"
        :showSearchIcon="false"
        :activeLink="activeLink"
      />
    </div>

    <div class="page">
      <div :class="{ 'page-main' : !fullPage, 'page-full' : fullPage }">
        <h1 v-if="!hidePageTitle">{{ title }} <a v-if="canEdit" :href="'/manager/pages/' + id" title="edit page" target="_blank"><small>[edit]</small></a></h1>
        <a v-if="hidePageTitle && canEdit" :href="'/manager/pages/' + id" title="edit page" target="_blank">[edit]</a>
        <EditorBlocks :blocks="blocks" />
      </div>
    </div>
  </ResponsivePage>
</template>

<script>
import ResponsivePage from './ResponsivePage'
import ChannelMenu from '../components/ChannelMenu.vue'
import EditorBlocks from '../components/EditorBlocks'
import HeroImage from '@dharmachakra/tbco-shared-styles/src/components/HeroImage'
import ChannelHero from '../components/ChannelHero.vue'
import { fetchPageData } from '../api/browser-api.js'

export default {
  components: {
    ResponsivePage,
    EditorBlocks,
    HeroImage,
    ChannelMenu,
    ChannelHero
  },
  props: {
    id: [String, Number],
    title: String,
    content: String,
    heroImage: String,
    marqueeImage: Object,
    heroTitle: String,
    fullPage: {
      type: Boolean,
      default: false
    },
    hidePageTitle: {
      type: Boolean,
      default: false
    },
    useMoreLinks: {
      type: Boolean,
      default: false
    },
    menu: Object,
    blocks: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      pageHeroImage: {}
    }
  },
  created() {
    if (this.marqueeImage) {
      this.pageHeroImage = {
        desktop: this.marqueeImage.path,
        tablet: this.marqueeImage.path,
        mobile: this.marqueeImage.path
      }
    } else {
      const pageData = fetchPageData()
      this.pageHeroImage = pageData.heroImage
    }
  },
  computed: {
    canEdit() {
      return (document.__TBCO__.page && document.__TBCO__.page.data.canEdit === true)
    },
    activeLink() {
      return window.location.pathname
    },
    menuItems() {
      if (!this.menu) {
        return []
      }

      // @todo - filter OM only menu items.
      return this.menu.menu_items.map(item => {
        return {
          name: item.url,
          label: item.name,
          href: item.url
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.page {
  margin-bottom: 3rem;
}

.page-main {
  margin: 0 auto;
  width: 100%;
  max-width: 720px;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 0 1.2rem;
}

.page-full{
  margin: 0 auto;
  width: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 0 0.5rem;
}

::v-deep .page {
  font-family: 'Source Sans Pro', sans-serif;

  p, ul, ol {
    font-size: 20px;
    letter-spaceing: 0.59px;
  }

  a {
    color: #000;
  }
}

h1, h2 {
  margin: 30px 0;
  padding: 0;
}

.channel-menu {
  padding: 1rem 0;
  border-bottom: 1px solid #979797;
  margin-bottom: 3em;
}
</style>
