<template>
  <ResponsivePage>
    <div class="t-project">
      <ChannelHero :title="title"
                :alt="title"
                subtitle="sign in to follow this channel"
                :imgref="heroImage"
                :subtitleURL="path"
      />
    </div>

    <div class="t-project channel-menu">
      <ChannelMenu />
    </div>

    <h2>Edit Post</h2>
    <div v-html="form"></div>
  </ResponsivePage>
</template>

<script>
import ResponsivePage from './ResponsivePage'
import ChannelHero from '../components/ChannelHero.vue'
import ChannelMenu from '../components/ChannelMenu.vue'

export default {
  components: {
    ResponsivePage,
    ChannelHero,
    ChannelMenu
  },
  props: {
    form: String,
    nid: [String, Number],
    title: String,
    type: String,
    content: String,
    heroImage: String,
    path: String
  }
}
</script>
