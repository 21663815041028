<template>
  <div>
    <ResponsiveFrontpage v-if="pageType === 'frontpage'" />
    <ResponsiveTriratnaPage v-else-if="pageType === 'triratna-page'" v-bind="pageContent" />
    <ResponsiveSangharakshitaPage v-else-if="pageType === 'sangharakshita-page'" v-bind="pageContent" />
    <ChannelPage v-else-if="pageType === 'channel'" v-bind="pageContent" />
    <SinglePostPage v-else-if="pageType === 'post'" v-bind="pageContent" />
    <AdminDashboard v-else-if="pageType === 'admin-dashboard'" />

    <EditChannelPage v-else-if="pageType === 'edit-channel'" v-bind="pageContent" />
    <EditPostPage v-else-if="pageType === 'edit-post'" v-bind="pageContent" />

    <SearchResultsPage v-else-if="pageType === 'search-results'" v-bind="pageContent" />
    <KeystonePage v-else-if="pageType === 'keystone'" v-bind="pageContent" />
    <Page v-else-if="pageType === 'page'" v-bind="pageContent" />
    <ResponsivePage v-else-if="pageType === 'raw'"><div v-html="pageHtml"></div></ResponsivePage>
    <OrderAddressListPage v-else-if="pageType === 'order-address-list'" v-bind="pageContent" />
    <PostForm v-else-if="pageType === 'post-form'" v-bind="pageContent" />

    <!-- Normal Error Page -->
    <ResponsiveError v-else-if="pageType === 'error'" />

    <!-- Default to error page if no matching page is found! -->
    <ResponsiveError v-else :message="'Unknown page type: ' + pageType"/>
  </div>
</template>

<script>
import ResponsivePage from '../pages/ResponsivePage'
import ResponsiveFrontpage from '../pages/ResponsiveFrontpage'
import SearchResultsPage from '../pages/SearchResultsPage'
import KeystonePage from '../pages/KeystonePage'
import Page from '../pages/Page'
import ChannelPage from '../pages/ChannelPage'
import EditChannelPage from '../pages/EditChannelPage'
import EditPostPage from '../pages/EditPostPage'
import SinglePostPage from '../pages/SinglePostPage'
import AdminDashboard from '../pages/AdminDashboard'
import ResponsiveError from '../pages/ResponsiveError'
import ResponsiveTriratnaPage from '../pages/ResponsiveTriratnaPage.vue'
import ResponsiveSangharakshitaPage from '../pages/ResponsiveSangharakshitaPage.vue'
import OrderAddressListPage from '../pages/OrderAddressListPage.vue'
import utils from '../api/utils.js'
import PostForm from '../pages/PostForm.vue'

export default {
  components: {
    AdminDashboard,
    ResponsivePage,
    ResponsiveFrontpage,
    KeystonePage,
    SearchResultsPage,
    Page,
    EditChannelPage,
    EditPostPage,
    ChannelPage,
    SinglePostPage,
    ResponsiveError,
    ResponsiveTriratnaPage,
    ResponsiveSangharakshitaPage,
    OrderAddressListPage,
    PostForm
  },
  props: {
    pageData: Object
  },
  created() {
    if (document.__TBCO__ && document.__TBCO__.page) {
      this.pageType = document.__TBCO__.page.type
      this.pageContent = document.__TBCO__.page.data
      this.pageHtml = document.__TBCO__.page.bodyHtml
    } else {
      this.pageType = 'error'
      if (!document.__TBCO__) document.__TBCO__ = {}
      document.__TBCO__.error = {
        'title': 'There\'s been a problem displaying this page',
        'message': 'No page spec found'
      }
    }
  },
  data () {
    return {
      pageType: undefined,
      pageContent: {},
      pageHtml: null
    }
  },
  mounted() {
    setTimeout(() => {
      utils.updateAllExternalLinksInDocument()
    }, 500)
  }
}
</script>
