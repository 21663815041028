<template>
  <ResponsivePage>
    <div class="t-project">
      <HeroImage alt="Sangharakshita"
        :imgref="pageHeroImage.desktop"
        :imgSet="pageHeroImage.mobile + ' 548w, ' + pageHeroImage.tablet + ' 734w, ' + pageHeroImage.desktop + ' 2876w'"
        :imgSizes="'(max-width: 414px) 548px, (max-width: 768px) 734px, 2876px'"
      />
    </div>
    <SingleSlider
      v-if="slider1"
      :stories="buildSlides(slider1)"
      :title="slider1.name"
      :subtitle="slider1.subtitle"
      :titleHref="slider1.url"
    />
    <div class="sangharakshita-divider"></div>
    <SingleSlider
      v-if="slider2"
      :stories="buildSlides(slider2)"
      :title="slider2.name"
      :subtitle="slider2.subtitle"
      :titleHref="slider2.url"
      titleBGColor="grey"
      slideTypeClass="slider-type__quotes"
    />
    <div class="sangharakshita-divider margin-bottom"></div>
    <!-- @todo - make signup go to sangharakshita.org/urgyen house -->
    <div class="t-tbco">
      <NewsletterSignup
        :hidden="!showNewsletterSignup"
        @hide="hideNewsletter"
        @show="showNewsletter"
        title="get regular updates from sangharaskshita.org and urgyen house"
        subtitle=""
        privacy="We respect your privacy. We will never spam you or sell your data."
        buttonColor='red'
      />
    </div>
    <div class="sangharakshita-divider margin-top"></div>

    <!-- @todo - slider showing selected sangharakshita talks -->

    <SingleSlider
      v-if="slider3"
      :stories="buildSlides(slider3)"
      :title="slider3.name"
      :subtitle="slider3.subtitle"
      :titleHref="slider3.url"
      titleBGColor="grey"
    />

    <!-- <div class="sangharakshita-divider"></div> -->
    <!-- @todo - slider showing selected sangharakshita series - uncomment above divider when implemented -->

    <div class="t-project">
      <SocialBar title="the buddhist centre [social]"
                  subtitle="follow, subscribe, only connect"
                  theme="sangharakshita"
      />
    </div>

    <div class="t-project" slot="footer">
      <FooterLinks title="the best of triratna online since 2013"
        subtitle="the best of triratna online since 2013"
        link="link"
        :menus="footerMenus"
        theme="sangharakshita"
      />
    </div>
  </ResponsivePage>
</template>

<script>
import axios from 'axios'
import { buildSlideBody } from '../api/sliders-api.js'
import ResponsivePage from './ResponsivePage'
import SingleSlider from '@dharmachakra/tbco-slider/src/components/SingleSlider'
import HeroImage from '@dharmachakra/tbco-shared-styles/src/components/HeroImage.vue'
import FooterLinks from '@dharmachakra/tbco-shared-styles/src/components/FooterLinks.vue'
import ChannelHeroImage from '../images/landing-hero.jpg'
import { NewsletterSignup } from '@dharmachakra/newsletter-signup-component'
import SocialBar from '@dharmachakra/tbco-shared-styles/src/components/SocialBar.vue'
import { fetchPageData } from '../api/browser-api.js'
import '@dharmachakra/newsletter-signup-component/dist/newsletter-signup-component.css'
import '@dharmachakra/tbco-panels/dist/tbco-panels.css'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faSquareFacebook, faInstagram, faYoutube, faSoundcloud } from '@fortawesome/free-brands-svg-icons'
library.add(faSquareFacebook, faInstagram, faYoutube, faSoundcloud)

export default {
  components: {
    ResponsivePage,
    SingleSlider,
    HeroImage,
    NewsletterSignup,
    SocialBar,
    FooterLinks
  },
  data () {
    return {
      pageHeroImage: {},
      foot1Menu: {},
      foot2Menu: {},
      foot3Menu: {},
      foot4Menu: {},
      foot5Menu: {},
      foot6Menu: {},
      canEdit: false,
      ChannelHeroImage,
      showNewsletterSignup: true,
      footerMenus: [],
      slider1: {},
      slider2: {},
      slider3: {}
    }
  },
  created () {
    if (document.__TBCO__.app && document.__TBCO__.app.menus) {
      this.foot1Menu = document.__TBCO__.app.menus.foot1 || {}
      this.foot2Menu = document.__TBCO__.app.menus.foot2 || {}
      this.foot3Menu = document.__TBCO__.app.menus.foot3 || {}
      this.foot4Menu = document.__TBCO__.app.menus.foot4 || {}
      this.foot5Menu = document.__TBCO__.app.menus.foot5 || {}
      this.foot6Menu = document.__TBCO__.app.menus.foot6 || {}

      this.footerMenus = [
        this.formatFooterMenu(this.foot1Menu),
        this.formatFooterMenu(this.foot2Menu),
        this.formatFooterMenu(this.foot3Menu),
        this.formatFooterMenu(this.foot4Menu),
        this.formatFooterMenu(this.foot5Menu),
        this.formatFooterMenu(this.foot6Menu)
      ]
    }

    this.slider1 = document.__TBCO__.slider1
    this.slider2 = document.__TBCO__.slider2
    this.slider3 = document.__TBCO__.slider3

    axios.get('/api/v1/roles/hide%20newsletter%20signup')
      .then(response => {
        if (response.data.has_role) this.showNewsletterSignup = false
      }).catch(() => {
        // @todo - log error?
      })

    const pageData = fetchPageData()
    this.pageHeroImage = pageData.heroImage
  },
  methods: {
    buildSlides(slider) {
      if (!slider.json) {
        return []
      }

      return buildSlideBody(slider.json, slider.random_order)
    },
    formatFooterMenu(menu) {
      menu.title = menu.name

      if (!menu.menu_items) {
        return menu
      }

      menu.links = menu.menu_items.map((item) => {
        item.label = item.name
        item.href = item.url
        return item
      })

      return menu
    },
    hideNewsletter () {
      axios.put('/api/v1/roles/hide%20newsletter%20signup')
        .then(response => {
          if (response.data.ok) this.showNewsletterSignup = false
        })
    },
    showNewsletter () {
      axios.delete('/api/v1/roles/hide%20newsletter%20signup')
        .then(response => {
          if (response.data.ok) this.showNewsletterSignup = true
        })
    }
  }
}
</script>

<style scoped lang="scss">
.t-project {
  .c-channel-hero__image-container {
    margin-top: 1.75rem;
  }
}

.sangharakshita-divider {
  width: 100%;
  height: 5px;
  background-color: #979797;
  margin: 0 auto;
}

.margin-bottom {
  margin-bottom: 10px;
}

.margin-top {
  margin-top: 10px;
}
</style>
