export function getNextLink(index, isActive) {
  const hrefs = flattenHrefs(index)
  for (var i = 0; i < hrefs.length; i++) {
    if (isActive(hrefs[i].href) && i + 1 < hrefs.length) {
      return hrefs[i + 1]
    }
  }
  return null
}

function flattenHrefs(index) {
  const hrefs = []

  for (var section in index) {
    var links = index[section]
    for (var link of links) {
      hrefs.push({ href: link.href, label: link.label })
      for (var sublink of link.links) {
        hrefs.push({ href: sublink.href, label: sublink.label })
      }
    }
  }

  return hrefs
}

export function getFirstLink(index) {
  for (var section in index) {
    var links = index[section]
    return links[0]
  }
}
